import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyleCard, SiteLogo } from '../PasswordList/style';
import {
  Back,
  Flex,
  Box,
  TextInput,
  Text,
  RotatableIcon,
} from '../../../../storyBook';
import usePassword from '../../../hooks/usePassword';
import theme from '../../../../theme';
import { Hr } from '../../../_common/style';
import Loader from '../../../_common/Loader';
import { RotableContainer } from './style';
import SubMenu from '../../../Timeline/SubMenu';
import AddPasswordModal from '../AddPasswordModal';
import { useHistory } from 'react-router-dom';
import { decrypt } from '../../../Utils/helper';

interface Params {
  passwordId: string;
}

const PasswordCard = () => {
  const { passwords, deletePassword, editPassword, fetchAllPasswords } =
    usePassword();
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const history = useHistory();

  const { passwordId } = useParams<Params>();
  const password = passwords.find((p) => p._id === passwordId);

  const secretKey = process.env.REACT_APP_SECRET_KEY || '';

  let decryptedPassword = '';

  if (password && password.password) {
    decryptedPassword = decrypt(password.password, secretKey);
  }

  if (!password) {
    return <Loader isLoading={true} />;
  }

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  const handleEditSubmit = async (updatedData: {
    website: string;
    username: string;
    password: string;
  }) => {
    await editPassword(password._id ?? '', updatedData);
    await fetchAllPasswords();
    setIsModalOpen(false);
  };

  const handleEdit = () => {
    setIsModalOpen(true);
    setModalProps({
      isEditMode: true,
      initialData: {
        site: password.website,
        userName: password.username,
        password: decryptedPassword,
      },
      onEdit: handleEditSubmit,
    });
  };

  const handleDelete = async () => {
    if (password._id) {
      await deletePassword(password._id);
    }
    history.push('/password');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <StyleCard>
      <Flex flexDirection="column">
        <RotableContainer>
          <RotatableIcon
            _id={password._id || ''}
            onToggle={handleMenuToggle}
            isOpen={openMenuId === password._id}
          />
          {openMenuId === password._id && (
            <SubMenu
              id={password._id}
              password
              showEditOption={true}
              onEdit={handleEdit}
              onDelete={handleDelete}
              setOpenMenuId={setOpenMenuId}
            />
          )}
        </RotableContainer>
        <Flex mb={3} alignItems="center">
          <Box pr={3}>
            <Back arrow onClick={handleBack} />
          </Box>
          <SiteLogo src={password.logoUrl} alt="logo" />
          <Text as="h3" color={theme.colors.offBlack}>
            {password.website}
          </Text>
        </Flex>
        <Hr mb={4} />
        <Box mb={4}>
          <TextInput
            placeholder="Username"
            value={password.username}
            onChange={() => {}}
            isCopy
          />
          <TextInput
            type="password"
            placeholder="Password"
            value={decryptedPassword}
            onChange={() => {}}
            isCopy
          />
        </Box>
        {isModalOpen && (
          <AddPasswordModal setAddModal={closeModal} {...modalProps} />
        )}
      </Flex>
    </StyleCard>
  );
};

export default PasswordCard;
