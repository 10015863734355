/* eslint-disable react/no-unescaped-entities */
import { useState, useRef } from 'react';
import {
  Box,
  Text,
  Back,
  Flex,
  FloatingButton,
  useIsMedia,
} from '../../storyBook';
import theme from '../../theme';
import { Hr } from '../_common/style';
import { ListContents, TextStyled, InnerCard } from './styles';
import { TbChevronRightPipe } from 'react-icons/tb';
import { TiArrowUpThick } from 'react-icons/ti';
import { FaCircleNodes } from 'react-icons/fa6';

const Documents = () => {
  const featuresRef = useRef(null);
  const benefitsRef = useRef(null);
  const useCasesRef = useRef(null);
  const securityRef = useRef(null);
  const timelinesRef = useRef(null);
  const snapNotesRef = useRef(null);
  const passwordSecurityRef = useRef(null);
  const demosRef = useRef(null);
  const dashboardRef = useRef(null);
  const addTimelinesRef = useRef(null);
  const addTimelinesCategoryRef = useRef(null);
  const editTimelinesRef = useRef(null);
  const statusUpdateRef = useRef(null);
  const searchTimelinesRef = useRef(null);
  const addSnapNotesRef = useRef(null);
  const editSnapNotesRef = useRef(null);
  const searchSnapNotesRef = useRef(null);
  const snapNoteCategoryRef = useRef(null);
  const addPasswordsRef = useRef(null);
  const editPasswordsRef = useRef(null);
  const searchPasswordsRef = useRef(null);
  const setMasterPasswordRef = useRef(null);
  const notificationsRef = useRef(null);
  const feedbackRef = useRef(null);

  const [showButton, setShowButton] = useState(false);
  const [visitedSections, setVisitedSections] = useState<
    React.RefObject<HTMLElement>[]
  >([]);
  const topRef = useRef(null);
  const { isMobile } = useIsMedia();

  // Function to scroll to the top of the page
  const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 65,
        behavior: 'smooth',
      });
      if (!visitedSections.includes(ref)) {
        setVisitedSections([...visitedSections, ref]);
      }
      setShowButton(true);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = (topRef: React.RefObject<HTMLElement>) => {
    if (topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop - 65,
        behavior: 'smooth',
      });
    }
    setShowButton(false);
  };

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      <Box mb={3}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Box ref={topRef} color={theme.colors.offBlack} p={2}>
        <Text as="h2" mb={2} color={theme.colors.offBlack}>
          Live Diary
        </Text>
        <Hr />
        <Text bold as="h3" mb={2} mt={3}>
          Overview
        </Text>
        <InnerCard>
          <Flex>
            <Box mr={4}>
              <TbChevronRightPipe
                size={30}
                color={theme.colors.supportingBlue}
              />
            </Box>
            <Text>
              <Text bold as="span">
                {' '}
                Live Diary{' '}
              </Text>{' '}
              is a universal application designed to enhance the organization
              and security of personal data through its integrated modules:
              Timelines, SnapNotes, and Passwords.
            </Text>
          </Flex>
        </InnerCard>
        <TextStyled>
          This comprehensive tool helps users manage their time, capture
          important information, and secure sensitive data all in one platform.
          Whether it&apos;s tracking significant life events, storing multimedia
          notes, or managing login credentials,{' '}
          <Text bold as="span">
            {' '}
            Live Diary{' '}
          </Text>{' '}
          offers a streamlined solution for maintaining order and security in
          daily life. Its user-friendly interface and robust features make it an
          essential application for anyone looking to effectively manage their
          personal information across multiple dimensions.
        </TextStyled>
        <Text bold as="h3" mb={2} mt={3}>
          Table of Contents
        </Text>
        <Box pl={4}>
          <ListContents
            as="li"
            color={visitedSections.includes(featuresRef)}
            onClick={() => scrollToRef(featuresRef)}
          >
            Features
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(benefitsRef)}
            onClick={() => scrollToRef(benefitsRef)}
          >
            Benefits
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(useCasesRef)}
            onClick={() => scrollToRef(useCasesRef)}
          >
            Use Cases
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(securityRef)}
            onClick={() => scrollToRef(securityRef)}
          >
            Security
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(timelinesRef)}
            onClick={() => scrollToRef(timelinesRef)}
          >
            Timelines
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(snapNotesRef)}
            onClick={() => scrollToRef(snapNotesRef)}
          >
            SnapNotes
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(passwordSecurityRef)}
            onClick={() => scrollToRef(passwordSecurityRef)}
          >
            Password
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(demosRef)}
            onClick={() => scrollToRef(demosRef)}
          >
            Demos
          </ListContents>
          <ListContents as="ul" mt={1}>
            <ListContents
              as="li"
              color={visitedSections.includes(dashboardRef)}
              onClick={() => scrollToRef(dashboardRef)}
            >
              Dashboard
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addTimelinesRef)}
              onClick={() => scrollToRef(addTimelinesRef)}
            >
              Add Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addTimelinesCategoryRef)}
              onClick={() => scrollToRef(addTimelinesCategoryRef)}
            >
              Add Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(editTimelinesRef)}
              onClick={() => scrollToRef(editTimelinesRef)}
            >
              Edit and delete Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(statusUpdateRef)}
              onClick={() => scrollToRef(statusUpdateRef)}
            >
              Status update and calendar Timelines{' '}
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(searchTimelinesRef)}
              onClick={() => scrollToRef(searchTimelinesRef)}
            >
              Search and filter Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addSnapNotesRef)}
              onClick={() => scrollToRef(addSnapNotesRef)}
            >
              Write SnapNotes
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(editSnapNotesRef)}
              onClick={() => scrollToRef(editSnapNotesRef)}
            >
              Edit delete and view SnapNotes
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(searchSnapNotesRef)}
              onClick={() => scrollToRef(searchSnapNotesRef)}
            >
              Search and filter SnapNotes
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(snapNoteCategoryRef)}
              onClick={() => scrollToRef(snapNoteCategoryRef)}
            >
              SnapNotes category
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addPasswordsRef)}
              onClick={() => scrollToRef(addPasswordsRef)}
            >
              Add Passwords
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(editPasswordsRef)}
              onClick={() => scrollToRef(editPasswordsRef)}
            >
              Edit delete and view Passwords
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(searchPasswordsRef)}
              onClick={() => scrollToRef(searchPasswordsRef)}
            >
              Search and filter Passwords
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(setMasterPasswordRef)}
              onClick={() => scrollToRef(setMasterPasswordRef)}
            >
              Set and Reset Master Password
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(notificationsRef)}
              onClick={() => scrollToRef(notificationsRef)}
            >
              Notifications
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(feedbackRef)}
              onClick={() => scrollToRef(feedbackRef)}
            >
              Feedback
            </ListContents>
          </ListContents>
        </Box>
        <Box ref={featuresRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Features
          </Text>
          <InnerCard>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text mb={2}>
                <Text bold as="span">
                  {' '}
                  Live Diary{' '}
                </Text>{' '}
                offers a range of features to help users manage their personal
                information effectively. These features include:
              </Text>
            </Flex>
          </InnerCard>
          <Box ref={timelinesRef}>
            <Text bold as="h3" mb={2} mt={3}>
              Timelines
            </Text>
            <TextStyled>
              The Timelines module in{' '}
              <Text bold as="span">
                {' '}
                Live Diary{' '}
              </Text>{' '}
              enables users to meticulously create and manage events or
              milestones in a sequential manner. This module offers
              comprehensive features for adding, editing, and deleting
              timelines, along with the capability to set reminders for
              impending events. Users can personalize their timelines with
              specific categories, detailed content, and exact dates and times,
              ensuring they stay on top of personal projects, appointments, and
              significant occasions. Additionally, the module incorporates a
              calendar view that visually represents these timelines,
              facilitating efficient planning and schedule management. To
              enhance timely notifications, users will receive three reminders
              for each event: one at 7 AM as a daily summary, another ten
              minutes before the event, and a final alert at the exact scheduled
              time. Each day&apos;s timeline is automatically updated to the
              dashboard for immediate visibility. Users also have the option to
              activate audible notifications, ensuring they never miss important
              updates. This functionality not only simplifies time management
              but also enriches the user&apos;s planning accuracy and
              punctuality.
            </TextStyled>
            <Flex
              ref={addTimelinesRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Add Timelines
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/add-timelines.gif"
                alt="add-timeline"
              />
            </Flex>
            <Flex
              ref={addTimelinesCategoryRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Add and delete timelines category
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/add-and-delete-category.gif"
                alt="add-timeline-category"
              />
            </Flex>
            <Flex
              ref={editTimelinesRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Edit and delete Timelines
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/edit-and-delete-timelines.gif"
                alt="edit-timeline"
              />
            </Flex>
            <Flex
              ref={statusUpdateRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={1}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />
                  Status update and calendar Timelines
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/status-and-calendar-timelines.gif"
                alt="status-timeline"
              />
            </Flex>
            <Flex
              ref={searchTimelinesRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Search and filter Timelines
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/search-and-filter-timelines.gif"
                alt="search-timeline"
              />
            </Flex>
          </Box>
          <Box ref={snapNotesRef}>
            <Text bold as="h3" mb={2} mt={3}>
              SnapNotes
            </Text>
            <TextStyled>
              SnapNotes is tailored for users who need to capture and organize
              information quickly and efficiently. This module enables the
              creation of notes with multimedia attachments, such as images
              captured directly from the device&apos;s camera or uploaded from
              storage. Users can label and categorize their notes for easy
              retrieval. SnapNotes is particularly useful for capturing details
              on-the-go, storing everything from quick reminders to detailed
              documentation of important activities or events, making it an
              invaluable tool for both personal and professional use.
            </TextStyled>
            <Flex
              ref={addSnapNotesRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Write SnapNotes
                </Text>
              </InnerCard>

              <img
                src="https://storage.cloud.google.com/live-diary-gif/write-snapnote.gif"
                alt="write-snapnote"
              />
            </Flex>
            <Flex
              ref={editSnapNotesRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2} background={theme.colors.primaryLight}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Edit and delete SnapNotes
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/edit-and-delete-snapnote.gif"
                alt="edit-snapnote"
              />
            </Flex>
            <Flex
              ref={searchSnapNotesRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  Search and filter SnapNotes
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/search-and-filter-snapnote.gif"
                alt="search-snapnote"
              />
            </Flex>
            <Flex
              ref={snapNoteCategoryRef}
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <InnerCard>
                <Text bold as="h4" p={2}>
                  <FaCircleNodes
                    style={{
                      color: theme.colors.supportingBlueLight,
                      marginRight: '5px',
                    }}
                  />{' '}
                  SnapNotes category
                </Text>
              </InnerCard>
              <img
                src="https://storage.cloud.google.com/live-diary-gif/snapnote-category.gif"
                alt="snapnote-category"
              />
            </Flex>
          </Box>
          <Box ref={passwordSecurityRef}>
            <Text bold as="h3" mb={2} mt={3}>
              Passwords
            </Text>
            <TextStyled mb={3}>
              The{' '}
              <Text bold as="span">
                Passwords
              </Text>{' '}
              module is dedicated to the secure storage and management of
              passwords and sensitive login details. It incorporates robust
              security features such as encryption and the ability to set and
              reset a master password, which protects all stored data. Users can
              easily add, edit, or delete password entries. This module also
              supports the secure retrieval of passwords, ensuring that users
              can access their credentials whenever necessary while maintaining
              high security standards to prevent unauthorized access.
            </TextStyled>
            <Hr />

            {/* Secure Password Management Section */}
            <Text bold as="h3" mb={2} mt={3}>
              Secure Password Management with Advanced Encryption
            </Text>
            <TextStyled pb={3}>
              At{' '}
              <Text bold as="span">
                Live Diary
              </Text>
              , we prioritize the security of your sensitive information above
              all else. The Passwords module is designed to offer you a safe and
              reliable way to store and manage your passwords and login details.
              Here's how we ensure your data remains secure:
            </TextStyled>
            <Hr />

            {/* 1. End-to-End Encryption */}
            <Text bold as="h4" mb={2} mt={3}>
              1. End-to-End Encryption
            </Text>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Encryption During Creation and Storage:</li>
              </Text>{' '}
              When you create a new password entry, your data is immediately
              encrypted using advanced algorithms before it leaves your device.
              This encrypted data is then securely stored in our database. For
              example, your password might be stored as something like:
            </TextStyled>

            {/* Code Snippet */}
            <InnerCard>
              <Text
                as="pre"
                fontFamily="monospace"
                fontSize="14px"
                style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
              >
                "password":
                "e1ac3a02618da84974b0beb3c3b91f83:4220db92faa50bca122a100ebde52ad0"
              </Text>
            </InnerCard>

            <TextStyled mb={3}>
              This ensures that even if someone were to access the stored data,
              it would be indecipherable without the proper decryption key.
            </TextStyled>

            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Secure Data Transmission:</li>
              </Text>{' '}
              All communication between your device and our servers is protected
              with HTTPS encryption, safeguarding your data from interception
              during transit.
            </TextStyled>
            <Hr />

            {/* 2. Secure Retrieval and Local Decryption */}
            <Text bold as="h4" mb={2} mt={3}>
              2. Secure Retrieval and Local Decryption
            </Text>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Encrypted Data Retrieval:</li>
              </Text>{' '}
              When you access your passwords, the encrypted data is fetched
              securely from our servers. The data remains encrypted during this
              process.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Local Decryption on Your Device:</li>
              </Text>{' '}
              The decryption of your passwords happens entirely on your device.
              This means that your decrypted passwords are never transmitted
              over the network or stored on our servers in plain text.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>No External Calls During Decryption:</li>
              </Text>{' '}
              The decryption process does not require any external calls or
              additional data fetching. This minimizes potential security risks
              and ensures a swift user experience.
            </TextStyled>

            {/* 3. Master Password Protection */}
            <Text bold as="h4" mb={2} mt={3}>
              3. Master Password Protection
            </Text>
            <TextStyled pb={3} pl={2}>
              <li>
                <Text bold as="span">
                  Single Access Point:
                </Text>{' '}
              </li>
              You set a master password that serves as the key to decrypt your
              stored passwords. Without this master password, accessing your
              encrypted data is virtually impossible.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Password Reset Protocols:</li>
              </Text>{' '}
              If you forget your master password, we have secure procedures in
              place to help you reset it without compromising the security of
              your stored information.
            </TextStyled>

            {/* 4. Advanced Security Features */}
            <Text bold as="h4" mb={2} mt={3}>
              4. Advanced Security Features
            </Text>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Robust Encryption Algorithms:</li>
              </Text>{' '}
              We utilize industry-standard encryption methods (such as AES-256)
              to protect your data, providing a high level of security trusted
              by experts worldwide.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <li>
                <Text bold as="span">
                  Biometric Authentication (Optional):
                </Text>{' '}
              </li>
              For added security and convenience, you can enable biometric
              authentication like fingerprint or facial recognition on supported
              devices.{' '}
              <Text as="span" bold>
                <i>Note: This feature will available in premium version.</i>
              </Text>
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <li>
                <Text bold as="span">
                  Automatic Logout:
                </Text>{' '}
              </li>
              The app will automatically log you out after a period of
              inactivity to prevent unauthorized access if your device is
              unattended.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <li>
                {' '}
                <Text bold as="span">
                  Regular Security Updates:
                </Text>{' '}
              </li>
              We continuously update our security protocols to protect against
              new threats and vulnerabilities.
            </TextStyled>

            {/* 5. Privacy and Data Protection */}
            <Text bold as="h4" mb={2} mt={3}>
              5. Privacy and Data Protection
            </Text>
            <TextStyled pb={3} pl={2}>
              <li>
                <Text bold as="span">
                  Zero-Knowledge Architecture:
                </Text>{' '}
              </li>
              Our system is designed so that only you can decrypt and access
              your passwords. We do not have access to your master password or
              decrypted data.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <li>
                <Text bold as="span">
                  No Data Sharing:
                </Text>{' '}
              </li>
              We do not share your personal data or stored passwords with any
              third parties.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <Text bold>
                <li>Compliance with Data Protection:</li>
              </Text>
              We adhere to all relevant data protection laws and regulations to
              ensure your privacy is maintained.
            </TextStyled>

            {/* Why Choose Live Diary's Passwords Module */}
            <Text bold as="h4" mb={2} mt={3}>
              Why Choose Live Diary's Passwords Module?
            </Text>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>Peace of Mind:</li>
              </Text>{' '}
              With multiple layers of security, you can trust that your
              sensitive information is well-protected.
            </TextStyled>
            <TextStyled pb={3} pl={2}>
              <Text bold as="span">
                <li>User Control:</li>
              </Text>{' '}
              You have full control over your data—you can add, edit, or delete
              your password entries at any time.
            </TextStyled>
            <TextStyled pb={3}>
              <Text bold as="span">
                Convenience and Security Combined:
              </Text>{' '}
              Enjoy the ease of accessing all your passwords in one place
              without compromising on security.
            </TextStyled>
          </Box>

          <Flex
            ref={addPasswordsRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Add Passwords
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/add-password.gif"
              alt="add-password"
            />
          </Flex>
          <Flex
            ref={editPasswordsRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Edit and delete Passwords
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/edit-delete-password.gif"
              alt="edit-password"
            />
          </Flex>
          <Flex
            ref={searchPasswordsRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Search Passwords
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/search-password.gif"
              alt="search-password"
            />
          </Flex>
          <Flex
            ref={setMasterPasswordRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Set and Reset Master Password
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/set-reset-password.gif"
              alt="set-reset-password"
            />
          </Flex>
        </Box>

        <Box ref={benefitsRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Benefits
          </Text>
          <InnerCard>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                <Text as="span">
                  <Text bold as="span">
                    {' '}
                    Live Diary{' '}
                  </Text>
                </Text>{' '}
                brings a multitude of benefits to its users by integrating
                functionalities across Timelines, SnapNotes, and Passwords into
                one streamlined platform:
              </Text>
            </Flex>
          </InnerCard>

          <Text as="ul" mt={1}>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Enhanced Organization:
              </Text>{' '}
              The application allows users to meticulously plan and track
              personal projects, appointments, and significant events with ease,
              enhancing their daily productivity and organization.
            </Text>

            <Text as="li" mb={1}>
              <Text bold as="span">
                Data Security:
              </Text>{' '}
              With robust encryption and security measures in place, users can
              store and manage sensitive information such as passwords and
              multimedia notes without compromising their privacy or security.
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Time Management:
              </Text>{' '}
              Timely notifications and reminders for upcoming events ensure that
              users stay on schedule. The application&apos;s automated features
              like adding timelines to the dashboard daily increase efficiency.
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Accessibility:
              </Text>{' '}
              The platform&apos;s user-friendly interface ensures that managing
              personal data, from notes to passwords, is accessible to all
              users, regardless of their technical skills.
            </Text>
          </Text>
        </Box>

        <Box ref={useCasesRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Use Cases
          </Text>
          <InnerCard>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                <Text bold as="span">
                  {' '}
                  Live Diary{' '}
                </Text>{' '}
                is designed to cater to a wide range of user needs and
                scenarios. Some common use cases include:
              </Text>
            </Flex>
          </InnerCard>

          <Text as="ul" mt={1}>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Personal organization:{' '}
              </Text>{' '}
              Users can create timelines to track personal events, set
              reminders, and manage their schedules
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Professional planning:{' '}
              </Text>{' '}
              Business professionals can use SnapNotes to capture meeting notes,
              store important documents, and manage project details
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Password management:{' '}
              </Text>{' '}
              Individuals can securely store and retrieve login credentials,
              ensuring easy access to online accounts
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Educational purposes:{' '}
              </Text>{' '}
              Students can use{' '}
              <Text bold as="span">
                {' '}
                Live Diary{' '}
              </Text>{' '}
              to organize study schedules, set assignment deadlines, and store
              course materials
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Creative projects:{' '}
              </Text>{' '}
              Artists, writers, and designers can utilize SnapNotes to capture
              ideas, inspiration, and visual references
            </Text>
          </Text>
        </Box>
        <Box ref={securityRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Security
          </Text>
          <InnerCard>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                Security is a cornerstone of the{' '}
                <Text bold as="span">
                  {' '}
                  Live Diary{' '}
                </Text>{' '}
                application, with several layers protecting the integrity and
                confidentiality of user data:
              </Text>
            </Flex>
          </InnerCard>

          <Text as="ul" mt={1}>
            <Text as="li">
              <Text bold as="span">
                Encryption:
              </Text>{' '}
              All password data is encrypted using advanced algorithms, ensuring
              that sensitive information is always protected from unauthorized
              access.
            </Text>
            <Text as="li">
              <Text bold as="span">
                Master Passwords:
              </Text>{' '}
              The ability to set and reset master passwords adds an additional
              level of security, safeguarding all stored passwords under a
              single, secure key.
            </Text>
            <Text as="li">
              <Text bold as="span">
                Secure Access:
              </Text>{' '}
              Users receive notifications and alerts that not only keep them
              updated but also ensure that any access to the application&apos;s
              comprehensive features is monitored and recorded for security.
            </Text>
          </Text>
          <TextStyled>
            Through these attributes,{' '}
            <Text bold as="span">
              {' '}
              Live Diary{' '}
            </Text>{' '}
            not only supports users in their daily organizational tasks but also
            ensures that their information remains secure and private, providing
            a reliable and efficient way to manage personal data.
          </TextStyled>
        </Box>
        <Box ref={demosRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Demos
          </Text>

          <InnerCard>
            <Flex>
              <Box mr={2}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                This section demonstrates the key features of{' '}
                <Text bold as="span">
                  {' '}
                  Live Diary&apos;s
                </Text>
                .
              </Text>
            </Flex>
          </InnerCard>
          <Flex
            ref={dashboardRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Dashboard
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/dashboard.gif"
              alt="dashboard"
            />
          </Flex>
          <Flex
            ref={notificationsRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Notifications
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/notification.gif"
              alt="notification"
            />
          </Flex>
          <Flex
            ref={feedbackRef}
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <InnerCard>
              <Text bold as="h4" p={2}>
                <FaCircleNodes
                  style={{
                    color: theme.colors.supportingBlueLight,
                    marginRight: '5px',
                  }}
                />{' '}
                Feedback
              </Text>
            </InnerCard>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/feedback.gif"
              alt="feedback"
            />
          </Flex>
        </Box>
        {showButton && (
          <FloatingButton
            icon={<TiArrowUpThick />}
            onClick={() => scrollToTop(topRef)}
          />
        )}
      </Box>
    </>
  );
};

export default Documents;
