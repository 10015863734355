import styled from 'styled-components';
import { buildStyles } from 'react-circular-progressbar';
import { Box } from '../../../storyBook';

export const ProgressBarWrapper = styled(Box)`
  width: 40px;
  height: 40px;
`;

export const getProgressBarStyles = (percentage: number) => {
  return buildStyles({
    textSize: '28px',
    pathColor: percentage > 100 ? '#FF0000' : '#4CAF50',
    trailColor: '#BDBDBD',
  });
};
