import styled from 'styled-components';
import { Box } from '../../../storyBook';
import { NavLink } from 'react-router-dom';

export const DesktopNavContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px; // Adjust this value for the gap between each menu item
`;

export const NavigationItem = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textColors};
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.textColors};
    background-color: ${({ theme }) => theme.colors.ultraLight};
    border: 1px solid ${({ theme }) => theme.colors.ultraLight};
  }

  svg {
    margin-right: 8px;
  }
  svg:last-child {
    margin-left: auto;
  }
`;
