import React, { useState, ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';
import { GoChevronUp, GoChevronDown } from 'react-icons/go';
import { RiDeleteBin6Line } from 'react-icons/ri';

import {
  Asterisk,
  Label,
  TextWrapper,
  Container,
  LabelWrapper,
  Input,
  Placeholder,
  ArrowWrapper,
  List,
  ListItem,
  SelectWrapper,
} from './style';

import { Props, OptionsProps } from './types';
import theme from '../../theme';

const Select: React.FC<Props> = ({
  label,
  onChange,
  options,
  required,
  onDelete,
  value,
  focus = false,
  placeholder,
}: Props) => {
  const [open, setOpen] = useState(false);
  const listRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useClickAway(listRef, () => {
    if (open) {
      setOpen(false);
    }
    setIsFocused(false);
  });

  const getOptions = (optionsArr: OptionsProps[]): ReactNode[] => {
    return optionsArr.map((option) => (
      <ListItem
        key={option.value}
        value={option.value}
        onClick={(): void => {
          onChange(option.value);
          setOpen(false);
        }}
      >
        {option.label}
        {onDelete &&
          option.value !== 'Create new' &&
          option.value !== 'Shopping' &&
          option.value !== 'Doctor' && (
            <RiDeleteBin6Line
              size="16px"
              style={{
                cursor: 'pointer',
                color: theme.colors.primaryRed,
                marginRight: '5px',
              }}
              onClick={(e): void => {
                e.stopPropagation();
                onDelete(option.value);
              }}
            />
          )}
      </ListItem>
    ));
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <Container ref={listRef}>
      {label && (
        <TextWrapper>
          <LabelWrapper>
            <Label>
              {label}
              {required && <Asterisk>*</Asterisk>}
            </Label>
          </LabelWrapper>
        </TextWrapper>
      )}
      <SelectWrapper>
        <Input
          onClick={(): void => {
            setOpen((prevState) => !prevState);
            setIsFocused(true); // Set focus state when opening
          }}
          open={open}
          isFocused={focus && isFocused}
        >
          <Placeholder selected={!!selectedOption}>
            {selectedOption ? selectedOption.label : placeholder}
          </Placeholder>
          <ArrowWrapper>
            {open ? <GoChevronUp size="14px" /> : <GoChevronDown size="14px" />}
          </ArrowWrapper>
        </Input>

        {open && (
          <List isFocused={focus && isFocused}>{getOptions(options)}</List>
        )}
      </SelectWrapper>
    </Container>
  );
};

export default Select;
