import styled from 'styled-components';
import { rem } from 'polished';
import { Flex } from '../../../../storyBook';

export const StyleCard = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  position: relative;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
  margin: ${rem(16)};
  padding: ${rem(16)};
  display: flex;
  flex-direction: column-reverse;
`;

export const SiteEntry = styled(Flex)`
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.ultraLight};
  }
`;

export const SiteLogo = styled.img`
  width: ${rem(35)};
  height: ${rem(35)};
  margin-right: ${rem(16)};
  object-fit: cover;
`;
