import { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { parseDateTime, useTimeOfDay } from '../Utils/helper';
import { Text, Box, Card } from '../../storyBook';
import theme from '../../theme';
import useTimeline from '../hooks/useTimeline';
import { formatDate } from '../Utils/helper';
import TimelineCard from './TodaysTimeline';
import { useSearch } from '../hooks/useSearch';
import { CenterText } from '../_common/style';
import { NotificationCard } from '../Notifications/style';

import {
  DashboardContainer,
  GreetingContainer,
  GreetingLine,
  NameLine,
  CardContainer,
  ButtonContainer,
  Button,
  ButtonText,
  ButtonMainText,
  ButtonSubText,
  TimelineContainer,
  Badge,
  CardGrid,
} from './style';

// Dashboard component
const Dashboard = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { greetingTime, image, color } = useTimeOfDay();
  const { timelines } = useTimeline();
  const { searchTerm, setActiveSearchArea } = useSearch();

  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const firstNameFallback = userDetails?.firstName;

  const today = new Date();
  const todayFormatted = formatDate(today);

  // Filter timelines where reminderDate is today

  const todaysTimelines = timelines
    .filter(
      (timeline) =>
        timeline.reminderDate &&
        formatDate(new Date(timeline.reminderDate)) === todayFormatted
    )
    .map((timeline) => ({
      ...timeline,
      combinedDateTime: parseDateTime(
        timeline.reminderDate ?? '',
        timeline.reminderTime ?? ''
      ),
    }));

  const now = new Date(); // Get the current date and time

  const searchTodayTimelines = todaysTimelines
    .filter((timeline) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return [
        timeline.category,
        timeline.content,
        timeline.reminderDate,
        timeline.reminderTime,
        timeline.status,
      ].some((field) => field?.toLowerCase().includes(lowerCaseSearchTerm));
    })
    .sort((a, b) => {
      const timeA = a.combinedDateTime.getTime();
      const timeB = b.combinedDateTime.getTime();
      const nowTime = now.getTime();

      const diffTimeA = Math.abs(timeA - nowTime);
      const diffTimeB = Math.abs(timeB - nowTime);

      const isAPast = timeA < nowTime;
      const isBPast = timeB < nowTime;

      // If one is past and the other is future, prioritize the future (upcoming)
      if (isAPast !== isBPast) {
        return isAPast ? 1 : -1;
      }

      // If both are in the future or both are in the past, sort by the absolute difference to the current time
      return diffTimeA - diffTimeB;
    });

  const timelinesCount = searchTodayTimelines.length;

  const handleTimelineClick = () => {
    history.push(`${path}/add-new-timeline`);
  };

  const handleSnapNoteClick = () => {
    history.push(`${path}/add-new-snapnote`);
  };

  const handlePasswordClick = () => {
    history.push(`/password`);
  };

  useEffect(() => {
    if (todaysTimelines.length > 1) {
      setActiveSearchArea('dashboard');
    } else {
      setActiveSearchArea('');
    }
  }, [todaysTimelines.length, setActiveSearchArea]);

  return (
    <Box>
      <DashboardContainer backgroundImage={image}>
        <GreetingContainer>
          <GreetingLine color={color} bold>
            {greetingTime},
          </GreetingLine>
          <NameLine color={color} bold>
            {firstNameFallback}
          </NameLine>
        </GreetingContainer>

        <CardContainer>
          <ButtonContainer timelinesCount={timelinesCount}>
            <Button onClick={handleTimelineClick}>
              <ButtonText>
                <ButtonMainText bold>Add Timeline</ButtonMainText>
                <ButtonSubText>Adding timeline for </ButtonSubText>
                <ButtonSubText> future</ButtonSubText>
              </ButtonText>
            </Button>
            <Button onClick={handleSnapNoteClick}>
              <ButtonText>
                <ButtonMainText bold>Write SnapNote</ButtonMainText>
                <ButtonSubText>Write a SnapNote for </ButtonSubText>
                <ButtonSubText> record</ButtonSubText>
              </ButtonText>
            </Button>
            <Button>
              <ButtonText onClick={handlePasswordClick}>
                <ButtonMainText bold> Save Password</ButtonMainText>
                <ButtonSubText>Save password for </ButtonSubText>
                <ButtonSubText> record</ButtonSubText>
              </ButtonText>
            </Button>
            {timelinesCount > 0 && (
              <TimelineContainer>
                <ButtonMainText bold>Today&apos;s Timelines</ButtonMainText>
                <Badge bold>{timelinesCount}</Badge>
              </TimelineContainer>
            )}
          </ButtonContainer>
        </CardContainer>
      </DashboardContainer>
      <Box mt={6}>
        {!todaysTimelines.length && (
          <NotificationCard>
            <CenterText>
              <Text bold>No Timelines Today</Text>
            </CenterText>
          </NotificationCard>
        )}
      </Box>

      {todaysTimelines.length > 0 && searchTodayTimelines.length === 0 && (
        <Card background={theme.colors.primary} height={150} border>
          <CenterText p={3}>
            No timelines found for the given search criteria.
          </CenterText>
        </Card>
      )}

      {searchTodayTimelines.length > 0 && (
        <Box>
          <CardGrid>
            {searchTodayTimelines.map((timeline) => (
              <TimelineCard key={timeline._id} timeline={timeline} />
            ))}
          </CardGrid>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
