export const parseEditTimelineCommand = (transcript: string) => {
  const cleanedTranscript = transcript.replace(/\bali\b/i, '').trim();

  // First look for the explicit update/change pattern:
  // e.g., "update time to 8pm" or "change category to Office"
  const updateRegex = /(update|change|edit|modify)\s+(\w+)\s+to\s+(.+)/i;
  const updateMatch = cleanedTranscript.match(updateRegex);

  let updates: Record<string, string> = {};
  if (updateMatch) {
    const field = updateMatch[2].trim().toLowerCase();
    const value = updateMatch[3].trim();
    updates = { [field]: value };
  } else {
    // No explicit "update" or "change" found, check if user just said "<field> <value>"
    // e.g., "time 8pm", "category Office", "date tomorrow", "content Call mom"
    const simpleUpdateRegex = /\b(category|content|date|time)\b\s+(.+)/i;
    const simpleUpdateMatch = cleanedTranscript.match(simpleUpdateRegex);
    if (simpleUpdateMatch) {
      const field = simpleUpdateMatch[1].trim().toLowerCase();
      const value = simpleUpdateMatch[2].trim();
      updates = { [field]: value };
    }
  }

  // Extract content if stated after "edit timeline"
  let content: string | null = null;

  // Remove the update portion to isolate content if any
  let contentTranscript = cleanedTranscript;
  if (updateMatch) {
    contentTranscript = contentTranscript.slice(0, updateMatch.index).trim();
  } else {
    // If simple update was found, remove that portion as well
    const simpleUpdateMatch = contentTranscript.match(
      /\b(category|content|date|time)\b\s+(.+)/i
    );
    if (simpleUpdateMatch) {
      contentTranscript = contentTranscript
        .slice(0, simpleUpdateMatch.index)
        .trim();
    }
  }

  contentTranscript = contentTranscript
    .replace(/^edit\s+timeline\s*/i, '')
    .trim();

  if (contentTranscript) {
    content = contentTranscript;
  }

  return {
    content: content || null,
    updates,
  };
};

export const getPromptForField = (field: string): string => {
  switch (field) {
    case 'content':
      return 'Please provide some content details of the timeline you want to edit.';
    default:
      return 'Please provide more details to identify the timeline.';
  }
};
