import * as chrono from 'chrono-node';

export const parseDateTime = (
  input: string
): { date?: string; time?: string; originalDateTimeText?: string } => {
  const results = chrono.parse(input);

  if (results.length > 0) {
    const result = results[0];
    const date = result.start.date();
    const originalDateTimeText = result.text;

    // Format the time into 12-hour format with AM/PM
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return {
      date: date.toISOString().split('T')[0], // Extract date part
      time: `${formattedHours}:${formattedMinutes} ${period}`, // 12-hour time format
      originalDateTimeText,
    };
  }

  return {};
};
