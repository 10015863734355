import styled from 'styled-components';
import { Text } from '../../storyBook';

export const ListContents = styled(Text)`
  color: ${({ color, theme }) =>
    color ? theme.colors.listColor : theme.colors.supportingBlueLight};
  cursor: pointer;
`;

export const TextStyled = styled(Text)`
  text-align: justify;
`;

export const InnerCard = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  margin: 18px 0px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  width: 100%;
`;
