import { Props } from './types';
import { Container, BackText, ArrowContainer } from './style';
import { BsChevronLeft as Icon } from 'react-icons/bs';
import { BiArrowBack as Icon2 } from 'react-icons/bi';
import theme from '../../theme';

const Back: React.FC<Props> = ({
  onClick,
  children,
  chevron = false,
  color = false,
  arrow = false,
  ...rest
}: Props) => (
  <Container onClick={onClick} {...rest}>
    <ArrowContainer>
      {chevron && (
        <Icon
          size="20px"
          color={color ? theme.colors.white : theme.colors.textColors}
        />
      )}
      {arrow && (
        <Icon2
          size="20px"
          color={color ? theme.colors.white : theme.colors.medium}
        />
      )}
    </ArrowContainer>
    {children && <BackText>{children}</BackText>}
  </Container>
);

export default Back;
