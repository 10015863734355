import styled from 'styled-components';
import { Button, Box, Flex } from '../../../storyBook';

export const GoogleButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 2px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.signBackground};
  }

  & > span {
    margin-left: 10px;
    font-weight: 500;
  }
`;

export const DividerWrapper = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  }

  &::before {
    margin-right: 0.5em;
  }

  &::after {
    margin-left: 0.5em;
  }
`;

export const Container = styled(Flex)`
  ${({ theme }) => theme.media.desktop} {
    background: ${({ theme }) => theme.colors.bodyBackground};
  }
`;
