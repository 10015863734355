import { AuthProvider } from './AuthContext';
import { TimelineProvider } from './TimelineContext';
import { childrenProps } from './TimelineContext/types';
import { SelectedCategoryProvider } from './SelectedCategoryContext';
import { SearchProvider } from './SearchContext';
import { NotificationProvider } from './NotificationsContext';
import { WebSocketProvider } from './WebSocketContext';
import { SnapNoteProvider } from './SnapNoteContext';
import { PasswordProvider } from './PasswordContext';
import usePushNotifications from '../hooks/usPushNotifications';
import { FeedbackProvider } from './Feedback';
import { CategoryProvider } from './CategoryContext';
import { VoiceControlProvider } from './VoiceControlContext';

const PushNotificationHandler: React.FC = () => {
  usePushNotifications();
  return null; // This component does not render anything
};

const CombinedProvider: React.FC<childrenProps> = ({ children }) => {
  return (
    <AuthProvider>
      <VoiceControlProvider>
        <TimelineProvider>
          <SelectedCategoryProvider>
            <SearchProvider>
              <SnapNoteProvider>
                <PasswordProvider>
                  <NotificationProvider>
                    <FeedbackProvider>
                      <CategoryProvider>
                        <WebSocketProvider>
                          {/* Add other providers here */}
                          <PushNotificationHandler />
                          {children}
                        </WebSocketProvider>
                      </CategoryProvider>
                    </FeedbackProvider>
                  </NotificationProvider>
                </PasswordProvider>
              </SnapNoteProvider>
            </SearchProvider>
          </SelectedCategoryProvider>
        </TimelineProvider>
      </VoiceControlProvider>
    </AuthProvider>
  );
};

export default CombinedProvider;
