import styled from 'styled-components';
import { Text } from '../storyBook';

export const Container = styled.div`
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.arrowColor};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.privacyColor};
  margin-left: 20px;
`;

export const LastUpdated = styled(Text)`
  font-size: 0.85em;
  color: ${({ theme }) => theme.colors.mediumDark};
  margin-left: 72px;
`;

export const Section = styled.section`
  margin-top: 20px;
`;

export const InnerSection = styled.section`
padding: 18px;
background-color: ${({ theme }) => theme.colors.modalBackground};  
}`;

export const TextPara = styled.p`
  color: ${({ theme }) => theme.colors.arrowColor};
  text-align: justify;
  font-size: 0.9em;
`;

export const Heading = styled.h4`
  color: ${({ theme }) => theme.colors.privacyColor};
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const List = styled.ul`
  padding-left: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 0.9em;
`;
