/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import conversationManager from './ConversationState';
import { parseDateTime } from '../utils';
import { Timeline } from '../../../../types';
import { exitCommandPatterns } from '../constants';
import { TimelineData } from '../../../../Context/TimelineContext/types';
import {
  checkMissingFields,
  extractCategoryUsingNLP,
  getPromptForField,
  parseAddTimelineCommand,
} from './HelperAddTimeline';

const defaultCategories = ['Shopping', 'Doctor', 'Office', 'School'];

export const addTimelineCommand = async (
  transcript: string,
  addTimeline: (data: TimelineData, userId: string) => Promise<Timeline | null>,
  addCategory: (name: string) => Promise<void>
): Promise<string | { type: 'exit'; message: string }> => {
  let { data } = conversationManager.state;
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const userId = userDetails._id;
  // Check if the transcript matches the exit command patterns
  if (exitCommandPatterns.some((pattern) => pattern.test(transcript))) {
    // Reset the conversation state
    conversationManager.resetState();
    // Return the exit action
    return {
      type: 'exit',
      message: 'Exiting add timeline. Returning to main menu.',
    };
  }

  if (!conversationManager.state.command) {
    const { content, category, date, time } =
      parseAddTimelineCommand(transcript);

    // Use NLP to extract a meaningful category if not found in defaults
    const finalCategory = category || extractCategoryUsingNLP(content || '');

    data = {
      content: content || '',
      category: finalCategory || 'Uncategorized',
      reminderDate: date || undefined,
      reminderTime: time || undefined,
      ring: true,
    };

    if (
      finalCategory &&
      !defaultCategories.includes(finalCategory.toLowerCase())
    ) {
      try {
        // Attempt to add the new category
        await addCategory(finalCategory);
        console.log(`Category "${finalCategory}" added successfully.`);
      } catch (error) {
        console.error(`Failed to add category "${finalCategory}":`, error);
      }
    }

    const missingFields = checkMissingFields(data as TimelineData);

    if (missingFields.length === 0) {
      const success = await addTimeline(data as TimelineData, userId);
      conversationManager.resetState();
      return success
        ? 'Your timeline has been added.'
        : 'There was an error adding your timeline.';
    } else {
      conversationManager.setState({
        command: 'add_timeline',
        data,
        expectedInput: missingFields[0] as
          | 'category'
          | 'content'
          | 'reminderDate'
          | 'reminderTime'
          | null,
      });
      return getPromptForField(missingFields[0]);
    }
  } else if (conversationManager.state.command === 'add_timeline') {
    data = handleOngoingConversation(data, transcript);

    const missingFields = checkMissingFields(data as TimelineData);

    if (missingFields.length === 0) {
      const success = await addTimeline(data as TimelineData, userId);
      conversationManager.resetState();
      return success
        ? 'Your timeline has been added.'
        : 'There was an error adding your timeline.';
    } else {
      conversationManager.setState({
        command: 'add_timeline',
        data,
        expectedInput: missingFields[0] as
          | 'content'
          | 'category'
          | 'reminderDate'
          | 'reminderTime'
          | null,
      });
      return getPromptForField(missingFields[0]);
    }
  }

  return '';
};

export const handleOngoingConversation = (data: any, transcript: string) => {
  data = { ...conversationManager.state.data };

  switch (conversationManager.state.expectedInput) {
    case 'category':
      data.category = transcript.trim();
      break;
    case 'content':
      data.content = transcript.trim(); // Ensure transcript is trimmed and stored
      break;
    case 'reminderDate': {
      const dateResult = parseDateTime(transcript);
      data.reminderDate = dateResult.date || undefined; // Extract date if available
      break;
    }
    case 'reminderTime': {
      const timeResult = parseDateTime(transcript);
      data.reminderTime = timeResult.time || undefined; // Extract time if available
      break;
    }
    default:
      break;
  }

  return data; // Return the updated timeline data
};
