import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { rem } from 'polished';

import { ColorNames } from '../../theme/types';

export const BaseModal = styled(ReactModal)`
  width: 100vw;
  height: 100vh;
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const Wrap = styled.div<{
  fullPage: boolean;
  background: ColorNames | null;
  noBackground?: boolean;
}>`
  position: fixed;
  background: ${({ theme, background, noBackground }) =>
    noBackground
      ? 'transparent'
      : background
      ? theme.colors[background]
      : theme.colors.cardBackground};
  overflow: auto;

  ${({ fullPage, theme }) =>
    fullPage
      ? css`
          width: 100%;
          height: calc(100% + 1px);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `
      : css`
          width: 100%;
          border-radius: ${rem(3)};
          max-height: calc(100% - 60px);
          max-width: calc(100% - 48px);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          ${theme.media.smallTablet} {
            width: 600px;
            max-width: calc(100% - 60px);
          }
        `}
`;

export const IconWrapper = styled.div<{
  iconBackground?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }): string => theme.colors.modalBackground};
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin: 0 5px;
  z-index: 1000;
`;
