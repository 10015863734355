import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderSignup from '../../../Header/SignupHeader';
import Loader from '../../../_common/Loader';
import {
  Modal,
  Box,
  Text,
  TextInput,
  Card,
  Button,
  Flex,
  useIsMedia,
} from '../../../../storyBook';
import { passwordValidation } from '../../../Utils/validation';
import { Hr } from '../../../_common/style';
import theme from '../../../../theme';
import useAuth from '../../../hooks/useAuth';
import { notify } from '../../../../storyBook/TostNotification';

const ResetMasterPassword = () => {
  const [newMasterPassword, setNewMasterPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const history = useHistory();
  const { isMobile } = useIsMedia();
  const { resetMasterPassword, loading } = useAuth();

  const handleBackClick = () => {
    history.push('/login');
  };

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (token) {
        await resetMasterPassword(token, newMasterPassword);
      }
    } catch (err: unknown) {
      const errorMsg = typeof err === 'string' ? err : 'An error occurred';
      notify({ message: errorMsg, type: 'error' });
    }

    setIsModalOpen(false);
    handleBackClick();
  };

  return (
    <>
      <HeaderSignup onBackClick={handleBackClick} />
      {loading && <Loader isLoading={loading} />}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          handleBackClick();
        }}
        fullPage={isMobile}
      >
        <Box m={3}>
          <Box mb={4}>
            {isMobile && (
              <HeaderSignup onBackClick={() => setIsModalOpen(false)} />
            )}
          </Box>
          <Card>
            <Box>
              <Text as="h3" mb={2} color={theme.colors.mediumDark}>
                Reset your Master Password
              </Text>
              <Hr mb={3} />
              <Box mb={4}>
                <Text as="span" mb={2}>
                  Please provide a new master password to complete your master
                  password reset.
                </Text>
              </Box>
            </Box>
            <Box mb={4}>
              <TextInput
                autoFocus
                placeholder="New Master Password"
                type="password"
                value={newMasterPassword}
                onChange={(e) => setNewMasterPassword(e.target.value)}
                validation={passwordValidation}
                onValidation={setIsPasswordValid}
              />
            </Box>
            <Hr mb={3} />
            <Flex flexDirection="row" textAlign="end">
              <Box mr={3}>
                <Button
                  styleType="primary"
                  size="small"
                  type="submit"
                  bold
                  onClick={handleSubmit}
                  disabled={!isPasswordValid}
                >
                  Continue
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondary"
                  size="small"
                  type="button"
                  onClick={handleBackClick}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default ResetMasterPassword;
