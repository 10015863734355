/* eslint-disable @typescript-eslint/no-use-before-define */
import { parseEditTimelineCommand } from './helperEditTimeline';
import { TimelineData } from '../../../../Context/TimelineContext/types';
import { Timeline } from '../../../../types';
import conversationManager from '../AddTimelineCommand/ConversationState';
import { exitCommandPatterns } from '../constants';
import { parseDateTime } from '../utils';
import { getPromptForField } from './helperEditTimeline';
import { formatDate } from '../../../../Utils/helper';

export const editTimelineCommand = async (
  transcript: string,
  timelines: Timeline[],
  editTimeline: (timelineId: string, updatedData: TimelineData) => Promise<void>
): Promise<string | { type: 'exit'; message: string }> => {
  const cleanedTranscript = transcript.trim();
  if (exitCommandPatterns.some((pattern) => pattern.test(cleanedTranscript))) {
    conversationManager.resetState();
    return {
      type: 'exit',
      message: 'Exiting edit timeline. Returning to main menu.',
    };
  }

  const { content, updates } = parseEditTimelineCommand(cleanedTranscript);
  const identifiedTimelineId =
    conversationManager.state.data.identifiedTimeline;

  // A short prompt once the timeline is identified
  const shortPrompt = (timeline: Timeline) =>
    `I found your timeline scheduled on ${formatDate(
      timeline.reminderDate ? new Date(timeline.reminderDate) : undefined
    )}. at ${
      timeline.reminderTime
    }. What would you like to edit (category, content, date, or time)?`;

  if (conversationManager.state.command === 'edit_timeline') {
    const currentData = { ...conversationManager.state.data };

    if (identifiedTimelineId) {
      // Timeline already identified
      if (updates && Object.keys(updates).length > 0) {
        // User provided updates
        const timeline = timelines.find((t) => t._id === identifiedTimelineId);
        if (!timeline) {
          conversationManager.resetState();
          return 'No timeline found. Please try again.';
        }

        applyDateTimeParsingToUpdates(updates);
        return applyUpdatesAndFinalize(
          timeline,
          currentData,
          updates,
          editTimeline
        );
      } else {
        // No updates provided, repeat short prompt
        const timeline = timelines.find((t) => t._id === identifiedTimelineId);
        if (!timeline) {
          conversationManager.resetState();
          return 'No timeline found. Please try again.';
        }
        return shortPrompt(timeline);
      }
    } else {
      // Still identifying timeline by content
      if (content) currentData.content = content.trim();
      conversationManager.setState({
        ...conversationManager.state,
        data: currentData,
      });

      const result = attemptTimelineFiltering(timelines, currentData);
      if (typeof result === 'string') {
        return result;
      } else {
        const { timeline } = result;
        conversationManager.setState({
          ...conversationManager.state,
          data: {
            ...currentData,
            identifiedTimeline: timeline._id,
          },
          expectedInput: null,
        });
        return shortPrompt(timeline);
      }
    }
  } else {
    // First invocation
    if (!content) {
      conversationManager.setState({
        command: 'edit_timeline',
        data: {},
        expectedInput: 'content',
      });
      return 'Please provide the content of the timeline you want to edit.';
    }

    const currentData: Partial<TimelineData> = { content: content.trim() };
    const hasUpdates = updates && Object.keys(updates).length > 0;
    const result = attemptTimelineFiltering(timelines, currentData);
    if (typeof result === 'string') {
      conversationManager.setState({
        command: 'edit_timeline',
        data: currentData,
        expectedInput: 'content',
      });
      return result;
    } else {
      const { timeline } = result;
      conversationManager.setState({
        command: 'edit_timeline',
        data: {
          ...currentData,
          identifiedTimeline: timeline._id,
        },
        expectedInput: null,
      });

      if (hasUpdates) {
        applyDateTimeParsingToUpdates(updates);
        return applyUpdatesAndFinalize(
          timeline,
          currentData,
          updates,
          editTimeline
        );
      } else {
        return shortPrompt(timeline);
      }
    }
  }
};

function attemptTimelineFiltering(
  timelines: Timeline[],
  data: Partial<TimelineData>
): string | { timeline: Timeline } {
  const { content } = data;
  const filteredTimelines = timelines.filter((timeline) => {
    return (
      !content ||
      (timeline.content &&
        timeline.content.toLowerCase().includes(content.toLowerCase()))
    );
  });

  if (filteredTimelines.length === 0) {
    return (
      'No timeline matches your criteria. ' +
      getPromptForField(nextFieldToAsk(data))
    );
  } else if (filteredTimelines.length > 1) {
    return (
      'Multiple timelines match your criteria. ' +
      getPromptForField(nextFieldToAsk(data))
    );
  }

  return { timeline: filteredTimelines[0] };
}

function nextFieldToAsk(data: Partial<TimelineData>): string {
  if (!data.content) return 'content';
  return 'content';
}

function applyDateTimeParsingToUpdates(updates: Record<string, string>) {
  if (updates['date']) {
    const dateTimeResult = parseDateTime(updates['date']);
    if (dateTimeResult.date) updates['date'] = dateTimeResult.date;
    if (dateTimeResult.time) updates['time'] = dateTimeResult.time;
  }
  if (updates['time']) {
    const dateTimeResult = parseDateTime(updates['time']);
    if (dateTimeResult.date) updates['date'] = dateTimeResult.date;
    if (dateTimeResult.time) updates['time'] = dateTimeResult.time;
  }
}

async function applyUpdatesAndFinalize(
  timeline: Timeline,
  data: Partial<TimelineData>,
  updates: Record<string, string>,
  editTimeline: (timelineId: string, updatedData: TimelineData) => Promise<void>
): Promise<string> {
  const newData: Partial<TimelineData> = { ...data };
  for (const field in updates) {
    const value = updates[field];
    if (field.includes('category')) {
      newData.category = value;
    } else if (field.includes('content')) {
      newData.content = value;
    } else if (field.includes('date')) {
      newData.reminderDate = value;
    } else if (field.includes('time')) {
      newData.reminderTime = value;
    }
  }

  return finalizeEdit(timeline, newData, editTimeline);
}

async function finalizeEdit(
  timeline: Timeline,
  data: Partial<TimelineData>,
  editTimeline: (timelineId: string, updatedData: TimelineData) => Promise<void>
): Promise<string> {
  try {
    const updatedData: TimelineData = {
      ...timeline,
      content: data.content || timeline.content,
      category: data.category || timeline.category,
      reminderDate: data.reminderDate || timeline.reminderDate,
      reminderTime: data.reminderTime || timeline.reminderTime,
      snapnotes: timeline.snapnotes?.map((note) => note.toString()) || [],
    };
    await editTimeline(timeline._id, updatedData);
    conversationManager.resetState();
    return 'Your timeline has been updated successfully.';
  } catch (error) {
    console.error(error);
    conversationManager.resetState();
    return 'An error occurred while updating your timeline.';
  }
}
