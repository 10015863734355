import styled from 'styled-components';
import { Box } from '../../../storyBook';
import { rem } from 'polished';

export const BadgeContainer = styled(Box)`
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.mediumDark};
`;

export const Badge = styled.span`
  position: absolute;
  top: ${rem(-6)};
  right: ${rem(-6)};
  height: ${rem(16)};
  min-width: ${rem(16)};
  padding: ${rem(2)} ${rem(4)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primaryRed};
  color: white;
  font-size: ${rem(12)};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;
