// src/components/VoiceControl/Commands/ConversationState.ts

export interface ConversationState {
  command: string | null;
  data: {
    content?: string | undefined;
    category?: string | undefined;
    reminderDate?: string | undefined;
    reminderTime?: string | undefined;
    ring?: boolean;
    identifiedTimeline?: string;
  };
  expectedInput:
    | 'content'
    | 'category'
    | 'reminderDate'
    | 'reminderTime'
    | null;
}

class ConversationManager {
  private static instance: ConversationManager;
  public state: ConversationState;
  public setStateCallback: ((state: ConversationState) => void) | null = null;

  private constructor() {
    this.state = {
      command: null,
      data: {},
      expectedInput: null,
    };
  }

  public static getInstance(): ConversationManager {
    if (!ConversationManager.instance) {
      ConversationManager.instance = new ConversationManager();
    }
    return ConversationManager.instance;
  }

  public setState(state: ConversationState) {
    this.state = state;
    if (this.setStateCallback) {
      this.setStateCallback(state);
    }
  }

  public resetState() {
    this.state = {
      command: null,
      data: {},
      expectedInput: null,
    };
  }
}

export default ConversationManager.getInstance();
