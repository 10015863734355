import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa';

import { Card, Box } from '../../../storyBook';
import theme from '../../../theme';

export const CardStyle = styled(Card)`
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative; /* Position the arrows absolutely inside the card */
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  padding-bottom: 35px;
`;

export const Label = styled.div`
  background: ${theme.colors.supportingBlue50};
  padding: 12px;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 8px;
  margin-bottom: 16px;
`;
export const ImageGalleryContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const ImageGallery = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 8px;
  z-index: 0;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 18px;
  padding-bottom: 16px;
  &:last-child {
    margin-right: 0;
  }
`;
export const ImageOverlay = styled.div`
  position: absolute;
  top: 20;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${theme.colors.modalBackground};
  border: 1px solid ${theme.colors.light};
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;

export const Image = styled.img`
  display: block;
  max-height: 80px;
  width: auto;
  cursor: pointer;
  border-radius: 8px;
`;

export const DownloadIcon = styled(FaDownload)`
  font-size: 18px;
  color: ${theme.colors.supportingBlue};
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const Arrow = styled(Box)`
  position: absolute;
  top: 95%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  color: ${theme.colors.arrowColor};
  opacity: 0.7;
  font-size: 18px; // Larger arrow size
  padding: 6px;

  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const LeftArrow = styled(Arrow)`
  left: 10px;
`;

export const RightArrow = styled(Arrow)`
  right: 10px;
`;
export const PreviewImage = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
  padding: 16px !important;
`;

export const RotableContainer = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.md};
`;
