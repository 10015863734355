import styled from 'styled-components';

export const RadioButtonWrapper = styled.div<{ isCompact: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  flex-direction: ${({ isCompact }) => (isCompact ? 'row' : 'column')};
  align-items: ${({ isCompact }) => (isCompact ? 'center' : 'flex-start')};
  ${({ theme }) => theme.media.smallTablet} {
    grid-template-columns: 1fr 4fr;
  }
`;

export const RadioLabel = styled.label<{
  isCompact: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  margin-right: ${({ isCompact }) => (isCompact ? '8px' : '0')};
  margin-bottom: ${({ isCompact }) => (isCompact ? '0' : '8px')};
`;

export const HiddenRadioInput = styled.input`
  display: none;

  &:checked + span {
    border: 2px solid ${({ theme }) => theme.colors.supportingBlueLight};
  }

  &:checked + span::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.supportingBlueLight};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const RadioThumb = styled.span`
  width: 16px;
  height: 16px;
  border: 2px solid ${({ theme }) => theme.colors.medium};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: border 0.2s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.mediumDark};
  }
`;

export const OptionText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textColors};
`;
