import { rem } from 'polished';

import media from './media';
import { lightColors } from './colors';
import { TypographyMap } from './types';

const typography: TypographyMap = {
  hero: {
    fontSize: rem(50),
    fontWeight: 700,
    lineHeight: rem(55),
    letterSpacing: '-0.31px',
    color: lightColors.primary,
    [media.tablet]: {
      fontSize: rem(80),
      lineHeight: rem(82),
      letterSpacing: '-0.5px',
    },
    [media.desktop]: {
      fontSize: rem(122),
      fontWeight: 800,
      lineHeight: rem(120),
      letterSpacing: '-0.76px',
    },
  },
  h1: {
    fontSize: rem(38),
    fontWeight: 700,
    lineHeight: rem(42),
    letterSpacing: '-0.37px',
    color: lightColors.secondary,
    [media.tablet]: {
      fontSize: rem(42),
      lineHeight: rem(48),
      letterSpacing: '-0.37px',
    },
    [media.desktop]: {
      fontSize: rem(60),
      lineHeight: rem(66),
      letterSpacing: '-0.37px',
    },
  },
  h2: {
    fontSize: rem(24),
    fontWeight: 700,
    lineHeight: rem(30),
    letterSpacing: '-0.15px',
    color: lightColors.secondary,
    [media.tablet]: {
      fontSize: rem(32),
      lineHeight: rem(42),
      letterSpacing: '-0.2px',
    },
    [media.desktop]: {
      fontSize: rem(36),
      lineHeight: rem(42),
      letterSpacing: '-0.22px',
    },
  },
  h3: {
    fontSize: rem(20),
    fontWeight: 500,
    lineHeight: rem(26),
    letterSpacing: '-0.15px',
    color: lightColors.secondary,
    [media.tablet]: {
      fontSize: rem(22),
      lineHeight: rem(32),
      letterSpacing: '-0.14px',
    },
    [media.desktop]: {
      fontSize: rem(22),
      lineHeight: rem(34),
      letterSpacing: '-0.14px',
    },
  },
  body: {
    fontSize: rem(16),
    fontWeight: 400,
    lineHeight: rem(26),
    letterSpacing: '-0.1px',
    color: lightColors.secondary,
  },
  p: {
    fontSize: rem(16),
    fontWeight: 400,
    lineHeight: rem(26),
    letterSpacing: '-0.1px',
    color: lightColors.secondary,
  },
  small: {
    fontSize: rem(12),
    fontWeight: 500,
    lineHeight: rem(26),
    letterSpacing: '-0.1px',
    color: lightColors.secondary,
  },
  subhead: {
    fontSize: rem(16),
    fontWeight: 500,
    lineHeight: rem(22),
    letterSpacing: '-0.1px',
    color: lightColors.secondary,
  },
  helper: {
    fontSize: rem(14),
    fontWeight: 400,
    lineHeight: rem(20),
    letterSpacing: '-0.1px',
    color: lightColors.secondary,
    [media.desktop]: {
      fontSize: rem(16),
      lineHeight: rem(22),
    },
  },
};

export default typography;
