import { Box, Text, Back, RadioButton } from '../../../../storyBook';
import { useThemeContext } from '../../../../theme/ThemeContext';
import theme from '../../../../theme';
import { useEffect } from 'react';

const Theme = () => {
  const { selectedTheme, setSelectedTheme } = useThemeContext();

  useEffect(() => {
    // Retrieve the theme from localStorage when the component mounts
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme) {
      setSelectedTheme(
        savedTheme as
          | 'light'
          | 'dark'
          | 'spring'
          | 'summer'
          | 'autumn'
          | 'winter'
      );
    }
  }, [setSelectedTheme]);

  const handleBack = () => {
    history.back();
  };

  const handleChange = (val: string) => {
    setSelectedTheme(
      val as 'light' | 'dark' | 'spring' | 'summer' | 'autumn' | 'winter'
    );
    // Save the selected theme to localStorage
    localStorage.setItem('selectedTheme', val);
  };

  return (
    <Box p={2}>
      <Box mb={3}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Text as="h2" pb={2} color={theme.colors.mediumDark}>
        Theme
      </Text>
      <Text pb={3}>Change the theme of the application</Text>
      <Box>
        <RadioButton
          name="theme"
          value={selectedTheme}
          onChange={(value: string) => handleChange(value)}
          ariaLabel={'radio button'}
          // Add 'lightPink' to the options array
          options={['light', 'dark', 'spring', 'summer', 'autumn', 'winter']}
        />
      </Box>
    </Box>
  );
};

export default Theme;
