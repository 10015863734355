import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Back,
  Box,
  Chip,
  Flex,
  Text,
  Card,
  RotatableIcon,
  Modal,
  Button,
  FloatingButton,
  useIsMedia,
} from '../../storyBook';
import Loader from '../_common/Loader';
import {
  ListContainer,
  ListItem,
  ItemContent,
  DateContent,
  StatusIndicator,
} from './style';
import { formatDate } from '../Utils/helper';
import theme from '../../theme';

import useTimeline from '../hooks/useTimeline';
import { CenterText } from '../_common/style';
import { getTimelineLabel, formatDateStyles } from '../Utils/helper';
import { useSelectedCategory } from '../hooks/useSelected';
import { useSearch } from '../hooks/useSearch';
import SubMenu from './SubMenu';
import DeleteTimelineModal from './DeleteTimelineModal';
import { FcCalendar } from 'react-icons/fc';
import TimelinesCalendar from './TimelinesCalendar';
import { GoCircle } from 'react-icons/go';
import SnapnoteLinkModal from './SnapnoteLinkModal';
import useSnapNote from '../hooks/useSnapNote';

const Timeline = () => {
  const {
    timelines,
    fetchAllTimelines,
    selectTimeline,
    selectedTimelineId,
    loading,
    error,
  } = useTimeline();
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const { selectedCategory } = useSelectedCategory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTimelineToDelete, setSelectedTimelineToDelete] = useState<
    string | null
  >(null);
  const { searchTerm, setActiveSearchArea } = useSearch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSnapnoteLinkModal, setIsSnapnoteLinkModal] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const { getSnapNote } = useSnapNote();
  const { isMobile } = useIsMedia();

  const history = useHistory();
  const path = useRouteMatch();

  const handleCalendarIconClick = () => {
    setShowCalendar(!showCalendar);
  };

  const onLinkSnapNote = (id: string) => {
    history.push(`/snapnotes?timelineId=${id}`);
  };

  useEffect(() => {
    if (timelines.length > 1) {
      setActiveSearchArea('timeline');
    } else {
      setActiveSearchArea('');
    }
  }, [timelines.length, setActiveSearchArea]);

  const handleTimelineClick = (id: string) => {
    selectTimeline(id);
  };
  const handleDeleteClick = (timelineId: string) => {
    setSelectedTimelineToDelete(timelineId);
    setIsDeleteModalOpen(true);
  };

  // Select the last timeline if there is no selected timeline
  useEffect(() => {
    if (timelines.length > 0) {
      selectTimeline(timelines[timelines.length - 1]._id);
    }
  }, [timelines]);

  const handleEdit = (id: string) => {
    history.push(`${path.url}/edit/${id}`);
  };
  if (!timelines.length) {
    return (
      <CenterText>
        <Text bold>No Timelines yet, add one now!</Text>
        <Box mt={3}>
          <FloatingButton
            onClick={() => history.push('/dashboard/add-new-timeline')}
          />
        </Box>
      </CenterText>
    );
  }

  // Filter and search timelines
  const filterAndSearchTimelines = () => {
    const filterTimelines =
      selectedCategory === 'Timelines' || !selectedCategory
        ? timelines
        : timelines.filter(
            (timeline) => timeline.category === selectedCategory
          );

    const searchTimelines = filterTimelines.filter((timeline) => {
      const lowerCaseSearchTerm = searchTerm
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ');

      const matchesSnapNotesKeywords =
        lowerCaseSearchTerm.includes('v') ||
        lowerCaseSearchTerm.includes('view') ||
        lowerCaseSearchTerm.includes('sn');

      // Show timelines with Snapnotes if search terms match partially with "view" or "snap"
      if (matchesSnapNotesKeywords) {
        return timeline.snapnotes && timeline.snapnotes.length > 0;
      }

      const searchableFields = [
        timeline.category,
        timeline.content,
        timeline.reminderTime,
        timeline.status,
      ];

      if (timeline.reminderDate) {
        searchableFields.push(...formatDateStyles(timeline.reminderDate));
      }

      return searchableFields.some((field) =>
        field?.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });

    return searchTimelines.sort((a, b) => {
      const dateA = a.dateCreated ? new Date(a.dateCreated) : new Date();
      const dateB = b.dateCreated ? new Date(b.dateCreated) : new Date();
      return dateB.getTime() - dateA.getTime(); // Sort in descending order
    });
  };

  const filteredAndSortedTimelines = filterAndSearchTimelines();

  if (loading) {
    return <Loader isLoading={true} />;
  }

  // If there are no timelines for the selected category, show a message
  if (filteredAndSortedTimelines.length === 0) {
    return (
      <Card background={theme.colors.primary} height={150} border>
        <Flex flexDirection="column" alignItems="center">
          <CenterText p={3}>
            No timelines found for the given search criteria.
          </CenterText>
          <Box width={!isMobile ? '332px' : '100%'}>
            <Button
              size="medium"
              styleType="secondaryOutline"
              onClick={() => window.location.reload()}
              bold
            >
              Back to Timelines
            </Button>
          </Box>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card background={theme.colors.primary} height={150} border>
        <CenterText p={3}>
          There was an error fetching the timelines. Please try again.
          <Box mt={3}>
            <Button
              size="medium"
              styleType="secondaryOutline"
              onClick={fetchAllTimelines}
              bold
            >
              Retry
            </Button>
          </Box>
        </CenterText>
      </Card>
    );
  }

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  const handleBack = () => {
    history.goBack();
  };

  const onDateChange = (date: Date) => {
    setSelectedDate(date);
    setShowCalendar(false);
    setIsModalOpen(true);
  };

  const tasksForSelectedDate = timelines.filter(
    (timeline) =>
      timeline.reminderDate &&
      new Date(timeline.reminderDate).toDateString() ===
        selectedDate.toDateString()
  );

  const handleClose = () => {
    setIsModalOpen(false);
    setShowCalendar(true);
  };
  const handleSnapnoteClick = async (snapnoteId: string) => {
    await getSnapNote(snapnoteId);
    setIsSnapnoteLinkModal(true);
  };

  return (
    <>
      <Box mb={2}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="space-between" alignItems="center">
          <Text as="h2" mb={[3, 3, 3, 4]} color={theme.colors.mediumDark}>
            Timelines
          </Text>
          {timelines.length > 0 && (
            <Box ml={2} onClick={handleCalendarIconClick} mt={[0, 0, 0, -3]}>
              <FcCalendar size={30} style={{ cursor: 'pointer' }} />
            </Box>
          )}
        </Flex>

        <Box mt={-3} mb={1}>
          <FloatingButton
            onClick={() => history.push('/dashboard/add-new-timeline')}
          />
        </Box>
      </Flex>

      {showCalendar && (
        <Modal isOpen={true} onClose={handleCalendarIconClick}>
          <Box p={[3, 3, 4]}>
            <TimelinesCalendar
              timelines={timelines}
              onDateChange={onDateChange}
              selectedDate={selectedDate}
            />
          </Box>
        </Modal>
      )}
      {isModalOpen && tasksForSelectedDate.length > 0 && (
        <Modal isOpen={true} onClose={handleClose}>
          <Box p={[3, 3, 4]}>
            <Text as="h3" mb={3}>
              Timelines for {formatDate(selectedDate)}
            </Text>
            {tasksForSelectedDate.map((timeline) => (
              <Card key={timeline._id} mb={2} p={3}>
                <Flex alignItems="center">
                  <GoCircle style={{ color: theme.colors.medium }} />
                  <Text as="h4" ml={2} bold>
                    {timeline.category}
                  </Text>
                </Flex>
                <Text as="p" mb={1}>
                  {timeline.content}
                </Text>
                <Text bold color={theme.colors.mediumDark}>
                  {timeline.reminderTime}
                </Text>
              </Card>
            ))}
          </Box>
        </Modal>
      )}
      {isSnapnoteLinkModal && (
        <SnapnoteLinkModal onClose={() => setIsSnapnoteLinkModal(false)} />
      )}
      <ListContainer>
        {filteredAndSortedTimelines.map((timeline) => {
          const isEdited =
            timeline.createdAt !== timeline.updatedAt &&
            timeline.status === 'Pending';

          return (
            <ListItem
              key={timeline._id}
              isSelected={timeline._id === selectedTimelineId}
              isEdited={isEdited}
              onClick={() => handleTimelineClick(timeline._id)}
            >
              <ItemContent>
                <Text as="small" bold color={theme.colors.mediumDark} ml={3}>
                  {getTimelineLabel(timeline.dateCreated)}
                </Text>
                <Flex alignItems="center">
                  <Text ml={2}>
                    <StatusIndicator status={timeline.status || 'Pending'} />
                  </Text>

                  <Text as="h4" bold={true} mx={2}>
                    {timeline.category}
                  </Text>
                  {isEdited && (
                    <Text as="small" color={theme.colors.mediumDark}>
                      (edited)
                    </Text>
                  )}
                </Flex>
                <Text ml={[3]}>{timeline.content}</Text>
              </ItemContent>
              <DateContent mr={2}>
                <Text as="span" bold mr={1}>
                  {timeline.reminderDate &&
                    formatDate(new Date(Date.parse(timeline.reminderDate)))}
                </Text>
                <Text as="p" color={theme.colors.mediumDark} bold>
                  {timeline.reminderTime
                    ? `${timeline.reminderTime}`
                    : 'No Time'}
                </Text>

                <Chip label={timeline.status} status={timeline.status} />
                <Box mt={1}>
                  {(timeline.snapnotes ?? []).length !== 0 && (
                    <Button
                      bold
                      size="small"
                      styleType="primary"
                      onClick={() =>
                        handleSnapnoteClick(
                          timeline.snapnotes?.[0]?.toString() ?? ''
                        )
                      }
                    >
                      View Snapnote
                    </Button>
                  )}
                </Box>
              </DateContent>
              <Box>
                <RotatableIcon
                  _id={timeline._id}
                  onToggle={handleMenuToggle}
                  isOpen={openMenuId === timeline._id}
                />

                {openMenuId === timeline._id && (
                  <SubMenu
                    id={timeline._id}
                    showEditOption
                    showStatusOption
                    onEdit={handleEdit}
                    onDelete={handleDeleteClick}
                    setOpenMenuId={setOpenMenuId}
                    status={timeline.status || 'Pending'}
                    linkSnapNote
                    onLinkSnapNote={onLinkSnapNote}
                  />
                )}
              </Box>
            </ListItem>
          );
        })}
      </ListContainer>

      <DeleteTimelineModal
        setOpenMenuId={setOpenMenuId}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        selectedTimelineToDelete={selectedTimelineToDelete}
      />
    </>
  );
};

export default Timeline;
