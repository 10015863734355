import { useState, useEffect } from 'react';
import { Box, TextInput, Text, Card, Flex, Button } from '../../../storyBook';
import { useLocation, useHistory } from 'react-router-dom';
import { LinkText, Form } from '../../_common/style';
import theme from '../../../theme';
import useIsMedia from '../../../storyBook/hooks/useIsMedia';
import useAuth from '../../hooks/useAuth';
import Loader from '../../_common/Loader';
import { FcGoogle } from 'react-icons/fc';
import { GoogleButton, DividerWrapper, Container } from './style';
import ForgotPasswordModal from '../ForgotPasswordModal';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import { ImSpinner8 } from 'react-icons/im';

import {
  identifierValidation,
  passwordValidation,
} from '../../Utils/validation';

const SignIn: React.FC = () => {
  const { isTablet, isMobile, isDesktop, isGiant } = useIsMedia();
  const isCombined = isTablet || isMobile;
  const history = useHistory();

  const [identifier, setIdentifier] = useState(''); // Email or phone
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isIdentifierValid, setIsIdentifierValid] = useState(false);
  const [forgotEmail, setForgotEmail] = useState(false);

  const location = useLocation();
  const { isAuthenticated, login, loading, forgotPassword } = useAuth();

  const isSignInPage = ['/', '/login'].includes(location.pathname);
  const apiBaseUrl = getApiBaseUrl();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await login({ identifier, password });
  };

  const handleGoogleSignIn = () => {
    window.location.href = `${apiBaseUrl}/auth/google`;
  };

  return (
    <>
      {loading && <Loader isLoading={loading} />}

      <Container
        justifyContent="center"
        alignItems="center"
        height={isCombined ? '100%' : '100vh'}
        flexDirection={isCombined ? 'column' : 'row'}
      >
        {/* Logo and Promotional Text */}
        <Box
          textAlign={isCombined ? 'center' : 'left'}
          p={2}
          pt={[2, 2, 3, 0]}
          pl={[0, 0, 3, 5]}
          m={[2, 2, 4, 5]}
        >
          <Text
            as={isCombined ? 'h2' : 'h1'}
            mb={2}
            color={theme.colors.supportingBlueLight}
          >
            livediary
          </Text>
          {isSignInPage && (isDesktop || isGiant || isTablet) && (
            <>
              <Text as="h3" mb={0}>
                Livediary transforms your voice into organized plans.
              </Text>
              <Text as="h3" mb={2}>
                Simplifying your day effortlessly.
              </Text>
            </>
          )}
        </Box>

        {/* Sign In Card */}
        <Card border={isDesktop} width={isTablet || isMobile ? '100%' : '40%'}>
          <Flex textAlign="center" flexDirection="column" mx={[0, 0, 6, 2]}>
            <Box m={2}>
              <Box>
                <Text as="h2" mb={2} color={theme.colors.mediumDark}>
                  Log in to your account
                </Text>
                <Box mb={4}>
                  <Text as="span" mb={2}>
                    Don&apos;t have an account?
                    <LinkText
                      bold
                      underline
                      pl={1}
                      onClick={() => {
                        history.push('/signup');
                      }}
                    >
                      Sign Up
                    </LinkText>
                  </Text>
                </Box>
                <Box mb={2}>
                  <GoogleButton
                    styleType="secondaryOutline"
                    type="submit"
                    bold
                    disabled={loading}
                    onClick={handleGoogleSignIn}
                  >
                    <FcGoogle size="30" style={{ paddingRight: '10px' }} />
                    Google login
                  </GoogleButton>
                </Box>
                <Box>
                  <DividerWrapper as="small" mb={2} color={theme.colors.medium}>
                    Or with email and password
                  </DividerWrapper>
                </Box>
              </Box>
              <Form onSubmit={handleSubmit}>
                <Box mb={4}>
                  <TextInput
                    autoFocus
                    placeholder="Mobile number or email address"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    validation={identifierValidation}
                    onValidation={(isValid) => setIsIdentifierValid(isValid)}
                  />
                  <TextInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validation={passwordValidation}
                    onValidation={(isValid) => setIsPasswordValid(isValid)}
                  />
                </Box>
                <Box mb={4}>
                  <Button
                    styleType="primary"
                    size="medium"
                    type="submit"
                    bold
                    disabled={!isPasswordValid || !isIdentifierValid || loading}
                  >
                    {loading ? (
                      <ImSpinner8
                        size={20}
                        style={{ verticalAlign: 'middle' }}
                      />
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </Box>
              </Form>
              <Box mb={4}>
                <LinkText bold onClick={() => setForgotEmail(true)}>
                  Forgotten password?
                </LinkText>
              </Box>
            </Box>
          </Flex>
        </Card>
      </Container>

      {forgotEmail && (
        <ForgotPasswordModal
          isMobile={isMobile}
          onClose={() => setForgotEmail(false)}
          setForgotEmail={setForgotEmail}
          onSubmitEmail={forgotPassword}
        />
      )}
    </>
  );
};

export default SignIn;
