/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { createCommands } from './Commands/createCommands';
import { Box, Button, Flex, Modal, Text } from '../../../storyBook';
import { CenterText } from '../../_common/style';
import conversationManager from '../WakeWord/Commands/AddTimelineCommand/ConversationState';
import useTimeline from '../../hooks/useTimeline';
import useCategory from '../../hooks/useCategory';
import { addTimelineCommand } from './Commands/AddTimelineCommand';
import theme from '../../../theme';
import Loader from '../../_common/Loader';
import { editTimelineCommand } from './Commands/EditTimelineCommand/editTimelineCommand';
import { useVoiceControl } from '../../Context/VoiceControlContext';

const VoiceControl = () => {
  const [isActivated, setIsActivated] = useState(false);
  const { isVoiceEnabled, toggleVoiceControl, wasVoiceControlEnabled } =
    useVoiceControl();
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [showModal, setShowModal] = useState(false);
  const [inactivityTimeout, setInactivityTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addTimeline, timelines, editTimeline } = useTimeline();
  const { addCategory } = useCategory();

  const [lastCommand, setLastCommand] = useState('');
  const [assistantReply, setAssistantReply] = useState('');
  const [hideButton, setHideButton] = useState(() => {
    return localStorage.getItem('hideEnableVoiceButton') === 'true';
  });

  const commands = React.useMemo(
    () => createCommands(addTimeline, addCategory, editTimeline, timelines),
    [addTimeline, addCategory, editTimeline, timelines]
  );

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  // Enable voice control after user interaction
  const enableVoiceControl = () => {
    toggleVoiceControl(speak);
  };

  const hideEnableVoiceControlButton = () => {
    setHideButton(true);
    localStorage.setItem('hideEnableVoiceButton', 'true');
  };

  // Start listening when voice control is enabled
  useEffect(() => {
    if (isVoiceEnabled) {
      SpeechRecognition.startListening({
        continuous: true,
        interimResults: true,
      });
    }
  }, [isVoiceEnabled]);

  // Text-to-speech feedback
  const speak = (text: string) => {
    SpeechRecognition.stopListening();

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-US';

    utterance.onend = () => {
      setTimeout(() => {
        resetTranscript(); // Clear any transcript that may have been captured
        SpeechRecognition.startListening({
          continuous: true,
          interimResults: true,
        });
      }, 500); // Add a 500ms delay
    };

    window.speechSynthesis.speak(utterance);
  };

  // Handle transcript updates and process commands
  useEffect(() => {
    const lowerCaseTranscript = transcript.toLowerCase().trim();

    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
    }

    // Check for exact wake word 'ali'
    if (lowerCaseTranscript === 'ali') {
      setIsActivated(true);
      resetTranscript(); // Clear previous transcript for a fresh start
      setLastCommand(''); // Clear last command
      setAssistantReply(''); // Clear assistant reply
      speak("I'm listening.");
      return;
    }

    // Check if wake word is part of a longer command
    if (lowerCaseTranscript.includes('ali')) {
      setIsActivated(true);
      const command = lowerCaseTranscript.replace('ali', '').trim();
      resetTranscript();
      processCommand(command);
      return;
    }

    setInactivityTimeout(
      setTimeout(() => {
        setShowModal(false);
      }, 3000)
    );
  }, [transcript]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleActionResult = (result: any) => {
    setIsLoading(false);
    if (result && typeof result === 'object' && result.type === 'exit') {
      handleExitCommand(result.message);
    } else if (typeof result === 'string') {
      setAssistantReply(result);
      speak(result);
      resetActivation();
    } else {
      // Handle unexpected action results
      const errorMessage = 'An error occurred while processing your command.';
      setAssistantReply(errorMessage);
      speak(errorMessage);
      resetActivation();
    }
  };

  const processCommand = (command: string) => {
    setShowModal(true);
    setIsLoading(true);
    setLastCommand(command); // Set the last command
    setAssistantReply(''); // Clear previous assistant reply

    if (conversationManager.state.command === 'add_timeline') {
      // Handle follow-up responses for missing fields in add timeline
      addTimelineCommand(command, addTimeline, addCategory)
        .then((response) => handleActionResult(response))
        .catch(() => {
          const errorMessage =
            'An error occurred while processing your command.';
          setAssistantReply(errorMessage);
          speak(errorMessage);
        });
      return;
    }

    if (conversationManager.state.command === 'edit_timeline') {
      // Handle follow-up responses for edit timeline
      editTimelineCommand(command, timelines, editTimeline)
        .then((response) => handleActionResult(response))
        .catch(() => {
          const errorMessage =
            'An error occurred while processing your command.';
          setAssistantReply(errorMessage);
          speak(errorMessage);
        });
      return;
    }

    let filteredCommand = command;
    const matchedCommand = commands.find((cmd) =>
      cmd.patterns.some((pattern) => {
        const match = command.match(pattern);
        if (match) {
          // Start the content after the match ends
          const matchEndIndex = match.index! + match[0].length;
          filteredCommand = command.slice(matchEndIndex).trim();
          return true;
        }
        return false;
      })
    );

    if (matchedCommand) {
      const actionResult = matchedCommand.action(filteredCommand);
      if (actionResult instanceof Promise) {
        actionResult
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => handleActionResult(response))
          .catch(() => {
            const errorMessage =
              'An error occurred while processing your command.';
            setAssistantReply(errorMessage);
            speak(errorMessage);
          });
      } else {
        handleActionResult(actionResult);
      }
    } else {
      // Handle unknown commands
      const unknownResponse =
        'Sorry, I did not understand that command. Please try again.';
      setAssistantReply(unknownResponse);
      speak(unknownResponse);
      resetActivation();
    }
  };

  const handleExitCommand = (message: string) => {
    // Reset the conversation manager state
    conversationManager.resetState();

    // Close the modal
    setShowModal(false);

    // Clear command and reply
    setLastCommand('');
    setAssistantReply('');

    // Reset activation state
    setIsActivated(false);

    // Speak the exit message
    speak(message);

    // Reset the transcript
    resetTranscript();
  };

  // Reset activation state after command processing or timeout
  const resetActivation = () => {
    setIsActivated(false);
    // Do not resetTranscript here; it's handled in speak()
  };

  return (
    <div>
      {!wasVoiceControlEnabled && !isVoiceEnabled && !hideButton ? (
        <Flex justifyContent="space-between" alignItems="center">
          <Button radius={false} bold onClick={enableVoiceControl}>
            <Text bold>Enable Voice Control</Text>
          </Button>
          <Box ml={1}>
            <Button
              radius={false}
              size="small"
              bold
              onClick={hideEnableVoiceControlButton}
            >
              <Text bold>x</Text>
            </Button>
          </Box>
        </Flex>
      ) : (
        <>
          <p>{listening}</p>
          <p>{isActivated} </p>

          <Modal
            isOpen={showModal}
            onClose={() => {
              setShowModal(false);
              setLastCommand('');
              setAssistantReply('');
            }}
            background="ultraLight"
          >
            <Box>
              {isLoading ? <Loader isLoading={true} /> : null}
              <CenterText bold p={2} pb={3}>
                <Text bold mb={2}>
                  Command: {lastCommand || 'Listening for your command...'}
                </Text>
                {assistantReply && (
                  <Text bold color={theme.colors.mediumDark}>
                    {assistantReply}
                  </Text>
                )}
              </CenterText>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default VoiceControl;
