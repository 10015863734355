import { createGlobalStyle } from 'styled-components';
import { defaults } from './defaults';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: ${defaults.fontFamily};
    font-size: ${defaults.fontSize};
    line-height: ${defaults.lineHeight};
    font-weight: ${defaults.fontWeight};
    background-color: ${({ theme }) => theme.colors.cardBackground};
    margin: 0;
    padding: 0;
  }
  h1, h2, h3, h4, h5, h6, span, a, p {
    margin: 0;
  }

  a {
    color: inherit;
  }
`;

export default GlobalStyle;
