import { useState } from 'react';
import {
  Modal,
  Box,
  Text,
  TextInput,
  Card,
  Button,
  Flex,
} from '../../../storyBook';
import { Hr } from '../../_common/style';
import theme from '../../../theme';
import { emailValidation } from '../../Utils/validation';
import HeaderSignup from '../../Header/SignupHeader';

type ForgotPasswordModalProps = {
  masterPassword?: boolean;
  isMobile: boolean;
  setForgotEmail: (forgotEmail: boolean) => void;
  onClose: () => void;
  onSubmitEmail: (email: string) => Promise<void>;
};

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  isMobile,
  masterPassword,
  onClose,
  onSubmitEmail,
  setForgotEmail,
}) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleSubmit = async () => {
    await onSubmitEmail(email);
    onClose();
  };

  return (
    <Box>
      <Modal
        isOpen={true}
        onClose={() => setForgotEmail(false)}
        fullPage={isMobile}
        closeIcon={!isMobile}
      >
        <Box m={3}>
          <Box mb={4}>
            {isMobile && (
              <HeaderSignup onBackClick={() => setForgotEmail(false)} />
            )}
          </Box>

          <Card>
            <Box>
              <Text as="h3" mb={2} color={theme.colors.mediumDark}>
                Find Your Account
              </Text>
              <Hr mb={3} />
              <Box mb={4}>
                <Text as="span" mb={2}>
                  {masterPassword
                    ? 'Please enter your email to reset your master password.'
                    : 'Please enter your email to search and reset your password.'}
                </Text>
              </Box>
            </Box>
            <Box mb={4}>
              <TextInput
                autoFocus
                placeholder=" Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                validation={emailValidation}
                onValidation={(isValid) => setIsEmailValid(isValid)}
              />
            </Box>
            <Hr mb={3} />
            <Flex flexDirection="row" textAlign="end">
              <Box mr={3}>
                <Button
                  styleType="primary"
                  size="small"
                  type="submit"
                  bold
                  onClick={handleSubmit}
                  disabled={!isEmailValid}
                >
                  Continue
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondary"
                  size="small"
                  type="button"
                  onClick={() => setForgotEmail(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
};

export default ForgotPasswordModal;
