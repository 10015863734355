import styled from 'styled-components';
import { space, color as styledSystemColor } from 'styled-system';
import theme from '../../theme';
import { TextProps } from './types';

const getTypographyStyle = (element?: string) => {
  return theme.typography[element || 'p'] || theme.typography.p;
};

const Text = styled.p.attrs<TextProps>(({ as = 'p' }) => ({ as }))<TextProps>`
  /* Default typography style (for 'p' tag) */
  ${theme.typography.p};

  /* Typography styles from theme based on 'as' prop */
  ${({ as }) => getTypographyStyle(as)};

  /* Utilize styled-system for margin and color shortcuts */
  ${space};
  ${styledSystemColor};

  /* Custom color logic with fallback */
  color: ${({ color, theme }) => color || theme.colors.textColors};

  /* Custom Prop Styles */
  ${({ bold }) => bold && 'font-weight: bold;'};
  ${({ underline }) => underline && 'text-decoration: underline;'};
  ${({ disabled, theme }) => disabled && `color: ${theme.colors.secondary};`};
`;

export default Text;
