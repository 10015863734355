/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState, useEffect } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import { Timeline, TimelineResponse } from '../../types'; // Define this type according to your needs
import { childrenProps, TimelineData, TimelineContextType } from './types';
import { notify } from '../../../storyBook/TostNotification';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import useAuth from '../../hooks/useAuth';

export const TimelineContext = createContext<TimelineContextType | null>(null);

export const TimelineProvider = ({ children }: childrenProps) => {
  const [timelines, setTimelines] = useState<Timeline[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedTimelineId, setSelectedTimelineId] = useState<string | null>(
    null
  );

  const [loading, setLoading] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();

  const apiBaseUrl = getApiBaseUrl();

  const selectTimeline = (id: string) => {
    setSelectedTimelineId(id);
  };

  const clearSelectedTimeline = () => {
    setSelectedTimelineId(null);
  };

  // Fetch all timelines
  const fetchAllTimelines = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<Timeline[]>({
        endpoint: `${apiBaseUrl}/api/timelines`,
        method: 'GET',
        includeToken: true,
      });
      setTimelines(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Fetch a single timeline
  // const fetchTimeline = async (timelineId: string) => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const response = await apiCaller<Timeline>({
  //       endpoint: `http://apiBaseUrl:8000/api/timelines/${timelineId}`,
  //       method: 'GET',
  //     });
  //     setSelectedTimeline(response);
  //   } catch (err) {
  //     handleError(err, setError);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Add a timeline
  const addTimeline = async (newTimelineData: TimelineData, userId: string) => {
    if (!userId) {
      console.error('No user ID available');
      return null;
    }

    const body = { ...newTimelineData, userId };
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<TimelineResponse>({
        endpoint: `${apiBaseUrl}/api/timelines`,
        method: 'POST',
        body,
        includeToken: true,
      });
      if (response.Timeline) {
        notify({ message: 'Timeline added successfully', type: 'success' });
        await fetchAllTimelines();
        return response.Timeline;
      }
      return null;
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Add Snapnote to Timeline
  const addSnapnoteToTimeline = async (
    timelineId: string,
    snapnoteId: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const body = { snapnoteId };
      const endpoint = `${apiBaseUrl}/api/timelines/${timelineId}/add-snapnote`;
      await apiCaller({
        endpoint: endpoint,
        method: 'POST',
        body,
        includeToken: true,
      });
      notify({
        message: 'Snapnote added to timeline successfully',
        type: 'success',
      });
      await fetchAllTimelines();
    } catch (err) {
      notify({ message: 'Failed to add snapnote to timeline', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Edit a timeline
  const editTimeline = async (
    timelineId: string,
    updatedData: TimelineData
  ) => {
    const body = updatedData as Record<string, unknown>;
    setLoading(true);
    setError(null);
    try {
      await apiCaller<Timeline>({
        endpoint: `${apiBaseUrl}/api/timelines/${timelineId}`,
        method: 'PUT',
        body,
        includeToken: true,
      });
      await fetchAllTimelines();
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Delete a timeline
  const deleteTimeline = async (timelineId: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/timelines/${timelineId}`,
        method: 'DELETE',
        includeToken: true,
      });
      notify({ message: 'Timeline deleted successfully', type: 'success' });
      await fetchAllTimelines(); // Optionally re-fetch timelines after deleting
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const scheduleTimelineReminder = async (
    timelineId: string,
    reminderData: any
  ) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/schedule`,
        method: 'POST',
        body: { ...reminderData, timelineId },
        includeToken: true,
      });
      notify({ message: 'Reminder scheduled successfully', type: 'success' });
    } catch (err) {
      notify({
        message: error || 'Failed to schedule reminder',
        type: 'error',
      });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all timelines on initial render
  useEffect(() => {
    if (isAuthenticated) {
      fetchAllTimelines();
    }
  }, [isAuthenticated]);

  return (
    <TimelineContext.Provider
      value={{
        timelines,
        selectedTimelineId,
        fetchAllTimelines,
        selectTimeline,
        clearSelectedTimeline,
        scheduleTimelineReminder,
        addTimeline,
        addSnapnoteToTimeline,
        editTimeline,
        deleteTimeline,
        loading,
        error,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};
