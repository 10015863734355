import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  Box,
  Modal,
  Text,
  Flex,
  RotatableIcon,
} from '../../../storyBook';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import useSnapNote from '../../hooks/useSnapNote';
import {
  CardStyle,
  Label,
  ImageGallery,
  ImageGalleryContainer,
  ImageOverlay,
  Image,
  ImageContainer,
  DownloadIcon,
  LeftArrow,
  RightArrow,
  PreviewImage,
  RotableContainer,
} from '../../SnapNotes/SnapNoteCard/style';
import SubMenu from '../../Timeline/SubMenu';
import { formatDate } from '../../Utils/helper';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Loader from '../../_common/Loader';
import { CenterText } from '../../_common/style';
import theme from '../../../theme';
import useTimeline from '../../hooks/useTimeline';

const SnapnoteLinkModal = ({ onClose }: { onClose: () => void }) => {
  const { selectedSnapNote, removeSnapNoteFromTimeline, loading } =
    useSnapNote();
  const apiBaseUrl = getApiBaseUrl();
  const galleryRef = useRef<HTMLDivElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const { fetchAllTimelines } = useTimeline();

  const history = useHistory();
  const path = useRouteMatch();

  useEffect(() => {
    const gallery = galleryRef.current;
    if (gallery) {
      setShowLeftArrow(gallery.scrollLeft > 0);
      setShowRightArrow(
        gallery.scrollLeft < gallery.scrollWidth - gallery.clientWidth
      );
      const handleScroll = () => {
        setShowLeftArrow(gallery.scrollLeft > 0);
        setShowRightArrow(
          gallery.scrollLeft < gallery.scrollWidth - gallery.clientWidth
        );
      };
      gallery.addEventListener('scroll', handleScroll);
      return () => gallery.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const gallery = galleryRef.current;
    if (gallery) {
      if (direction === 'left') {
        gallery.scrollBy({ left: -gallery.offsetWidth, behavior: 'smooth' });
      } else {
        gallery.scrollBy({ left: gallery.offsetWidth, behavior: 'smooth' });
      }
    }
  };

  const getImageUrl = (image: string) => {
    if (image.startsWith('http://') || image.startsWith('https://')) {
      return image;
    }
    return `${apiBaseUrl}/${image}`;
  };

  const handleImageClick = (image: string) => {
    setPreviewImage(getImageUrl(image));
  };

  const downloadImage = (image: string) => {
    const url = getImageUrl(image);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', url.substring(url.lastIndexOf('/') + 1));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  const handleRemoveFromTimeline = async () => {
    if (selectedSnapNote && selectedSnapNote._id) {
      await removeSnapNoteFromTimeline(selectedSnapNote._id);
      fetchAllTimelines();
      onClose();
    }
  };

  if (
    !selectedSnapNote ||
    !selectedSnapNote.images ||
    selectedSnapNote.images.length === 0
  ) {
    return (
      <Card background={theme.colors.primary} height={150} border>
        <CenterText p={3}>No images available for this Timeline!</CenterText>
      </Card>
    );
  }

  const handleEdit = () => {
    const newPath = path.url.replace('/timeline', '');
    history.push(`${newPath}/snapnotes/edit/${selectedSnapNote._id}`);
  };

  if (loading || !selectedSnapNote) {
    return <Loader isLoading={true} />;
  }

  return (
    <Box>
      <Modal isOpen={true} onClose={onClose}>
        <ImageGalleryContainer>
          <CardStyle>
            <RotableContainer>
              <RotatableIcon
                _id={selectedSnapNote?._id ?? ''}
                onToggle={() => handleMenuToggle(selectedSnapNote?._id ?? '')}
                isOpen={openMenuId === selectedSnapNote?._id}
              />
              {openMenuId === selectedSnapNote._id && (
                <SubMenu
                  id={selectedSnapNote._id}
                  showEditOption={true}
                  onEdit={handleEdit}
                  onDelete={handleRemoveFromTimeline}
                  setOpenMenuId={setOpenMenuId}
                  removeSnapNote
                  snapNote
                />
              )}
            </RotableContainer>
            <Label>
              <Flex flexDirection="column">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text bold>{selectedSnapNote.label}</Text>
                </Flex>
                <Text as="small">
                  {formatDate(selectedSnapNote.dateCreated)}
                </Text>
              </Flex>
            </Label>
            <ImageGallery ref={galleryRef}>
              {selectedSnapNote.imageUrls?.map((image, index) => (
                <ImageContainer key={index}>
                  <Image
                    src={image}
                    style={{ width: '100%', height: 'auto' }}
                    alt={`SnapNote Image ${index}`}
                    onClick={() => handleImageClick(image)}
                  />
                  <ImageOverlay>
                    <DownloadIcon onClick={() => downloadImage(image)} />
                  </ImageOverlay>
                </ImageContainer>
              ))}
            </ImageGallery>

            {showLeftArrow && <LeftArrow onClick={() => scroll('left')} />}
            {showRightArrow && <RightArrow onClick={() => scroll('right')} />}
            {previewImage && (
              <Modal onClose={closePreview} isOpen={!!previewImage}>
                <PreviewImage src={previewImage} alt="Preview" />
              </Modal>
            )}
          </CardStyle>
        </ImageGalleryContainer>
      </Modal>
    </Box>
  );
};

export default SnapnoteLinkModal;
