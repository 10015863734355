import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ProgressBarWrapper, getProgressBarStyles } from './style';
const ProgressBar = ({
  storageUsed,
  storageQuota,
}: {
  storageUsed: number;
  storageQuota: number;
}) => {
  let percentage = 0;
  if (storageQuota > 0) {
    percentage = (storageUsed / storageQuota) * 100;
  }

  // Correcting potential issues with invalid percentage
  if (isNaN(percentage) || percentage < 0) {
    percentage = 0;
  } else if (percentage > 100) {
    percentage = 100;
  }

  const roundedPercentage = Math.round(percentage);

  return (
    <ProgressBarWrapper>
      <CircularProgressbar
        value={roundedPercentage}
        text={`${roundedPercentage}%`}
        styles={getProgressBarStyles(roundedPercentage)}
      />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
