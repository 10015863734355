import styled from 'styled-components';
import { Box, Flex } from '../../storyBook';
import { FaEllipsisV } from 'react-icons/fa';
import { rem } from 'polished';
import { getStatusColor } from '../../storyBook/Chip/style';

type StatusIndicatorProps = {
  status: 'Done' | 'Pending';
};

export const ListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: ${rem(8)};
  box-shadow: 0 ${rem(4)} ${rem(8)} rgba(0, 0, 0, 0.3);

  ${({ theme }) => theme.media.desktop} {
    margin: ${rem(16)};
    padding: ${rem(16)};
  }
`;

export const ListItem = styled(Flex)<{
  isSelected: boolean;
  isEdited: boolean;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${rem(8)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.listItemColor};
  background: ${({ theme, isSelected, isEdited }) =>
    isSelected
      ? theme.colors.supportingBlue50
      : isEdited
      ? theme.colors.primaryLight
      : ' '};
  &:last-child {
    border-bottom: none;
  }
`;

export const ItemContent = styled(Box)`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 ${rem(16)};
  word-wrap: break-word;
`;

export const DateContent = styled(Box)`
  flex-grow: 0;
  flex-shrink: 0;
`;

export const RotatableIconContainer = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  ${(props) => (props.isOpen ? 'transform: rotate(90deg);' : '')}
`;

export const RotatableIcon = styled(FaEllipsisV)`
  transition: transform 0.3s ease;
  color: ${(props) => props.theme.colors.medium};

  &:focus {
    outline: none;
  }
`;

export const StatusIndicator = styled.span<StatusIndicatorProps>`
  height: ${rem(10)};
  width: ${rem(10)};
  background-color: ${({ status }) => getStatusColor(status)};
  border-radius: 50%;
  display: inline-block;
  margin-left: -${rem(18)};
`;

export const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: ${rem(50)};

  svg {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryRed};
    }
  }
`;
