// VoiceControlContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import SpeechRecognition from 'react-speech-recognition';

const VoiceControlContext = createContext<{
  isVoiceEnabled: boolean;
  wasVoiceControlEnabled: boolean;
  toggleVoiceControl: (speak?: (text: string) => void) => void;
}>({
  isVoiceEnabled: false,
  wasVoiceControlEnabled: false,
  toggleVoiceControl: () => {},
});

export const VoiceControlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(() => {
    return localStorage.getItem('isVoiceEnabled') === 'true';
  });

  const [wasVoiceControlEnabled, setWasVoiceControlEnabled] = useState(() => {
    return localStorage.getItem('wasVoiceControlEnabled') === 'true';
  });

  const toggleVoiceControl = (speak?: (text: string) => void) => {
    setIsVoiceEnabled((prev) => {
      const newValue = !prev;

      // Update localStorage for both states
      localStorage.setItem('isVoiceEnabled', newValue.toString());
      if (newValue) {
        localStorage.setItem('wasVoiceControlEnabled', 'true');
        setWasVoiceControlEnabled(true);
        SpeechRecognition.startListening({
          continuous: true,
          interimResults: true,
        });

        if (speak) {
          speak(
            'Voice control enabled. After each command, say ali to complete command.'
          );
        }
      } else {
        SpeechRecognition.stopListening(); // Ensure real-time synchronization
        if (speak) {
          speak('Voice control disabled.');
        }
      }

      return newValue;
    });
  };

  useEffect(() => {
    if (!isVoiceEnabled) {
      SpeechRecognition.stopListening(); // Stop listening if disabled
    }
  }, [isVoiceEnabled]);

  return (
    <VoiceControlContext.Provider
      value={{ isVoiceEnabled, wasVoiceControlEnabled, toggleVoiceControl }}
    >
      {children}
    </VoiceControlContext.Provider>
  );
};

export const useVoiceControl = () => useContext(VoiceControlContext);
