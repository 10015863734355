import { useEffect, useState, useMemo } from 'react';
import SnapNoteCard from '../SnapNoteCard';
import useSnapNote from '../../hooks/useSnapNote';
import Loader from '../../_common/Loader';
import {
  Card,
  Flex,
  Text,
  Back,
  Box,
  FloatingButton,
  Chip,
} from '../../../storyBook';
import { CenterText } from '../../_common/style';
import theme from '../../../theme';
import { useHistory } from 'react-router-dom';
import { useSearch } from '../../hooks/useSearch';
import { formatDateStyles } from '../../Utils/helper';
import { GoStarFill } from 'react-icons/go';
import ProgressBar from '../ProgressBar';

const SnapNoteList = () => {
  const { snapNotes, toggleStar, loading, quota } = useSnapNote();
  const history = useHistory();
  const { searchTerm, setActiveSearchArea } = useSearch();
  const [selectedCategory, setSelectedCategory] = useState('general');
  const [showStarred, setShowStarred] = useState(false);
  const isEmpty = snapNotes.length === 0;

  const categories = useMemo(
    () => [...new Set(snapNotes.map((note) => note.category))],
    [snapNotes]
  );

  const filteredSnapNotes = useMemo(() => {
    return snapNotes.filter((snapNote) => {
      const matchesSearchTerm = searchTerm
        .toLowerCase()
        .trim()
        .split(/\s+/)
        .some(
          (term) =>
            snapNote.label?.toLowerCase().includes(term) ||
            snapNote.content?.toLowerCase().includes(term) ||
            formatDateStyles(snapNote.dateCreated?.toString() ?? '').some(
              (date) => date.includes(term)
            )
        );
      return (
        (snapNote.category === selectedCategory ||
          selectedCategory === 'general') &&
        matchesSearchTerm &&
        (!showStarred || snapNote.isStarred)
      );
    });
  }, [snapNotes, searchTerm, selectedCategory, showStarred]);

  useEffect(() => {
    if (!isEmpty) {
      setActiveSearchArea(filteredSnapNotes.length >= 0 ? 'snapnotes' : '');
    }
  }, [filteredSnapNotes.length, setActiveSearchArea]);

  if (loading) return <Loader isLoading={true} />;

  return (
    <>
      <Box mb={2}>
        <Back arrow onClick={() => history.goBack()} />
      </Box>
      {!isEmpty && (
        <Flex justifyContent="space-between">
          <Text
            as="h2"
            mb={[3, 3, 3, 4]}
            color={theme.colors.mediumDark}
            mr={1}
          >
            SnapNotes
          </Text>
          <Box>
            <FloatingButton
              onClick={() => history.push('/dashboard/add-new-snapnote')}
            />
          </Box>
          <Flex flexDirection="column">
            <Flex alignItems="center" mt={-1} mb={1}>
              <Text bold>Storage</Text>
              <Box ml={2}>
                <ProgressBar
                  storageUsed={quota.storageUsed}
                  storageQuota={quota.storageQuota}
                />
              </Box>
            </Flex>
            <Flex alignItems="center">
              <Text bold>Favorites</Text>
              <Box ml={2} mt={1} onClick={() => setShowStarred(!showStarred)}>
                <GoStarFill
                  size={20}
                  color={
                    showStarred ? theme.colors.pending : theme.colors.medium
                  }
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex
        mb={2}
        flexDirection="row"
        overflowX="auto"
        style={{ gap: '10px' }}
        alignItems="center"
      >
        {categories.map((category) => (
          <Box
            key={category}
            onClick={() => setSelectedCategory(category)}
            py={2}
            style={{ minWidth: 'fit-content' }}
          >
            <Chip
              label={category.toUpperCase()}
              status={category}
              active={selectedCategory === category}
            />
          </Box>
        ))}
      </Flex>

      <Flex flexDirection="column-reverse">
        {isEmpty ? (
          <CenterText>
            <Text bold>No SnapNotes found, write one now!</Text>
            <Box mt={3}>
              <FloatingButton
                onClick={() => history.push('/dashboard/add-new-snapnote')}
              />
            </Box>
          </CenterText>
        ) : filteredSnapNotes.length > 0 ? (
          filteredSnapNotes.map((snapNote) => (
            <SnapNoteCard
              key={snapNote._id}
              snapNote={snapNote}
              toggleStar={() => toggleStar(snapNote._id ?? '')}
            />
          ))
        ) : (
          <Card background={theme.colors.primary} height={150} border>
            <CenterText p={3}>
              No SnapNotes found for the given search criteria.
            </CenterText>
          </Card>
        )}
      </Flex>
    </>
  );
};

export default SnapNoteList;
