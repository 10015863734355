/* eslint-disable @typescript-eslint/no-explicit-any */
import { addTimelineCommand } from './AddTimelineCommand';
import { Timeline } from '../../../types';
import { TimelineData } from '../../../Context/TimelineContext/types';
import { editTimelineCommand } from './EditTimelineCommand/editTimelineCommand';
export const createCommands = (
  addTimeline: (data: TimelineData, userId: string) => Promise<Timeline | null>,
  addCategory: (category: string) => Promise<void>,
  editTimeline: (
    timelineId: string,
    updatedData: TimelineData
  ) => Promise<void>,
  timelines: Timeline[]
) => [
  {
    patterns: [
      /add timeline/i,
      /create timeline/i,
      /new timeline/i,
      /add a timeline/i,
      /create a timeline/i,
      /new a timeline/i,
    ],
    action: (transcript: string) => {
      return addTimelineCommand(transcript, addTimeline, addCategory);
    },
  },
  {
    patterns: [
      /edit timeline/i,
      /update timeline/i,
      /change timeline/i,
      /modify timeline/i,
    ],
    action: (transcript: string) => {
      return editTimelineCommand(transcript, timelines, editTimeline);
    },
  },
  {
    patterns: [
      /how many timelines/i,
      /number of timelines/i,
      /tell me my timelines/i,
    ],
    action: () => {
      console.log('You have 3 timelines today.');
      return 'You have 3 timelines today.';
    },
  },
  {
    patterns: [
      /tell me about live diary/i,
      /what is live diary/i,
      /what is live the diary about/i,
      /what is life diary/i,
    ],
    action: () => {
      return 'Live Diary is an all-in-one application designed to enhance personal organization, productivity, and security. It integrates three powerful modules—Timelines, SnapNotes, and Passwords—to help users manage their time, capture important information, and secure sensitive data in a single platform. With its seamless interface, it simplifies complex tasks, making it easy for users to stay on top of their schedules and responsibilities. Additionally, its advanced encryption and privacy-focused design ensure your data remains safe and exclusively accessible to you.';
    },
  },
  {
    patterns: [
      /how are you/i,
      /how are you doing/i,
      /how is it going/i,
      /what's up/i,
    ],
    action: () => {
      return 'I am doing great! How can I assist you today?';
    },
  },
  {
    patterns: [/what is the time/i, /what time is it/i, /tell me the time/i],
    action: () => {
      const date = new Date();
      return `The current time is ${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    },
  },
  {
    patterns: [/what is the date/i, /what date is it/i, /tell me the date/i],
    action: () => {
      const date = new Date();
      return `Today is ${date.toDateString()}`;
    },
  },
  {
    patterns: [/what is your name/i, /what should I call you/i, /who are you/i],
    action: () => {
      return 'You can call me Live Diary Assistant. How can I help you today?';
    },
  },
  {
    patterns: [/thank you/i, /thanks/i, /appreciate it/i, /that's helpful/i],
    action: () => {
      return 'You are welcome! Feel free to ask me anything.';
    },
  },
  {
    patterns: [/exit/i, /quit/i, /close/i],
    action: () => {
      return { type: 'exit', message: 'Goodbye! Returning to the main menu.' };
    },
  },
  {
    patterns: [/hello/i, /hi/i, /hey/i, /howdy/i],
    action: () => {
      return 'Hello! How can I assist you today?';
    },
  },
  {
    patterns: [/password/i, /passwords/i, /what is password/i],
    action: () => {
      return 'Live Diary provides a secure password manager to help you store and manage your passwords. You can add, view, edit, and delete your passwords with ease. Your passwords are encrypted and protected with advanced security measures to ensure your data remains safe and confidential.';
    },
  },
  {
    patterns: [/snapnote/i, /snapnotes/i, /note/i, /notes/i, /note-taking/i],
    action: () => {
      return 'SnapNotes is a feature in Live Diary that allows you to capture and organize your notes effortlessly. You can create, view, edit, and delete notes with ease. SnapNotes is designed to help you stay organized and productive by providing a simple and intuitive note-taking experience.';
    },
  },
];
