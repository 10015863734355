// theme/index.ts
import typography from './typography';
import spacing from './spacing';
import media from './media';
import { breakpointNames, breakpoints } from './media/breakpoints';
import {
  lightColors,
  darkColors,
  springColors,
  summerColors,
  autumnColors,
  winterColors,
} from './colors';
import { Theme } from './types';

export const lightTheme: Theme = {
  typography,
  spacing,
  colors: lightColors,
  media,
  breakpoints,
  breakpointNames,
};

export const darkTheme: Theme = {
  typography,
  spacing,
  colors: darkColors,
  media,
  breakpoints,
  breakpointNames,
};

export const springTheme: Theme = {
  typography,
  spacing,
  colors: springColors,
  media,
  breakpoints,
  breakpointNames,
};

export const summerTheme: Theme = {
  typography,
  spacing,
  colors: summerColors,
  media,
  breakpoints,
  breakpointNames,
};

export const autumnTheme: Theme = {
  typography,
  spacing,
  colors: autumnColors,
  media,
  breakpoints,
  breakpointNames,
};

export const winterTheme: Theme = {
  typography,
  spacing,
  colors: winterColors,
  media,
  breakpoints,
  breakpointNames,
};

const theme = lightTheme;
export default theme;
