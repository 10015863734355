/* eslint-disable @typescript-eslint/no-explicit-any */
import nlp from 'compromise';
import { parseDateTime } from '../utils';
import { TimelineData } from '../../../../Context/TimelineContext/types';
const defaultCategories = ['Shopping', 'Doctor', 'Office', 'School'];

export const extractCategoryUsingNLP = (content: string): string | null => {
  if (!content) return null;

  const stopWords = ['i', 'the', 'and', 'you', 'have', 'a', 'an', 'to', 'of']; // Common stop words
  const doc = nlp(content);

  // Extract nouns as potential categories
  let nouns = doc.nouns().toSingular().out('array');

  // Filter out stop words
  nouns = nouns.filter(
    (word: string) => !stopWords.includes(word.toLowerCase())
  );

  // Return the most relevant noun if available
  return nouns.length > 0 ? nouns[0] : null;
};

// Helper function to generate prompts for missing fields
export const getPromptForField = (field: string): string => {
  switch (field) {
    case 'category':
      return 'What category should I set for the timeline?';
    case 'content':
      return 'What is the content of the timeline?';
    case 'reminderDate':
      return 'What date should I set for the timeline?';
    case 'reminderTime':
      return 'What time should I set for the timeline?';
    default:
      return '';
  }
};

// Utility function to extract information from the transcript
export const parseAddTimelineCommand = (transcript: string) => {
  const filteredTranscript = transcript
    .replace(/has been added/i, '')
    .replace(/timeline/i, '')
    .replace(/I did not understand that command/i, '')
    .replace(/ Please try again/i, '')
    .trim();

  const dateTimeResult = parseDateTime(filteredTranscript);

  let content = filteredTranscript;
  if (dateTimeResult.originalDateTimeText) {
    content = content.replace(dateTimeResult.originalDateTimeText, '').trim();
  }

  const words = content.split(' ');
  const category = words.find((word) =>
    defaultCategories.some(
      (defaultCategory) => defaultCategory.toLowerCase() === word.toLowerCase()
    )
  );

  content = category
    ? content.replace(new RegExp(`\\b${category}\\b`, 'i'), '').trim()
    : content;

  return {
    content: content || null,
    category: category || null,
    date: dateTimeResult.date || null,
    time: dateTimeResult.time || null,
  };
};

// Helper to check missing fields
export const checkMissingFields = (data: Partial<TimelineData>): string[] => {
  const missingFields: string[] = [];

  if (!data.category || data.category === 'Uncategorized')
    missingFields.push('category');
  if (!data.content) missingFields.push('content');
  if (!data.reminderDate) missingFields.push('reminderDate');
  if (!data.reminderTime) missingFields.push('reminderTime');

  return missingFields;
};
