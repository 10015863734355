import styled, { keyframes } from 'styled-components';
import { Box, Flex } from '../../storyBook';
import { FiSearch } from 'react-icons/fi';
import { rem } from 'polished';
import { LinkText } from '../_common/style';

export const HeaderContainer = styled(Box)<{ isSticky?: boolean }>`
  background-color: ${({ theme }) => theme.colors.ultraLight};
  padding-top: ${rem(10)};
  top: ${({ isSticky }) => (isSticky ? '0' : 'auto')};
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  z-index: 1000;
`;

export const UserAvatar = styled(Box)<{ isClickable: boolean }>`
  width: ${rem(35)};
  height: ${rem(35)};
  border-radius: ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mediumDark};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.supportingBlueLight};
  transition: box-shadow 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.colors.supportingBlueLight};
    color: ${({ theme }) => theme.colors.white};
    box-shadow: inset 0 0 0 ${rem(2)} ${({ theme }) => theme.colors.white};
  }
`;

export const FlexContainer = styled(Flex)<{ isSticky?: boolean }>`
  transition: transform 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  top: ${({ isSticky }) => (isSticky ? '0' : 'auto')};
`;

export const SearchFlexContainer = styled(Flex)`
  position: sticky;
  top: 0;
  transition: transform 0.5s ease;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.ultraLight};
  padding: ${rem(10)} ${rem(15)};
  ${({ theme }) => theme.media.desktop} {
    padding: ${rem(10)} ${rem(80)} ${rem(10)} ${rem(65)};
  }
`;

export const FilterAvatarButton = styled(Box)`
  width: ${rem(40)};
  height: ${rem(35)};
  border-radius: ${rem(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mediumDark};
  cursor: pointer;
  border: ${rem(2)} solid ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.light};
  transition: box-shadow 0.3s ease;
  margin-left: ${rem(10)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.ultraLight};
    color: ${({ theme }) => theme.colors.mediumDark};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const TextInput = styled.input`
  width: 100%;
  height: ${rem(35)};
  border-radius: ${rem(10)};
  padding-left: ${rem(40)};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mediumDark};
  align-items: center;
  justify-content: center;
  border: ${rem(2)} solid ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.light};
  transition: box-shadow 0.3s ease;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.mediumDark};
  }
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.light};
    box-shadow: 0 0 ${rem(4)} ${({ theme }) => theme.colors.supportingBlueLight};
  }
`;

export const SearchIcon = styled(FiSearch)`
  position: absolute;
  left: ${rem(10)};
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.mediumDark};
  pointer-events: none;
`;

// Animation for sliding in from the right
const slideInFromRight = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

// Animation for sliding in from the bottom
const slideInFromBottom = keyframes`
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
`;

export const FilterCard = styled.div`
  position: fixed;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mediumDark};
  background: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${rem(80)};
  width: 100%;
  height: auto;
  bottom: 0;
  top: auto;
  z-index: 1000;
  animation: ${slideInFromBottom} 0.3s ease-out forwards;

  ${({ theme }) => theme.media.desktop} {
    text-align: left;
    top: 0px;
    right: 0;
    width: 300px;
    height: 100%;
    animation: ${slideInFromRight} 0.3s ease-out forwards;
    z-index: 1000;
  }
`;

export const CategoryLink = styled(LinkText)`
  display: block;
  padding: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.mediumDark};
  font-weight: 600;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ultraLight};
    color: ${({ theme }) => theme.colors.veryDark};
  }

  .icon {
    margin-right: 8px; // Adjust this value as needed
    display: inline-block; // Ensures the margin applies correctly
    vertical-align: middle; // Adjusts the icon's alignment with the text
  }
`;
