/* eslint-disable @typescript-eslint/no-use-before-define */
// SnapNoteCard.tsx
import { useState, useRef, useEffect, useCallback } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa';
import { Modal, Card, Box, RotatableIcon } from '../../../storyBook';
import { SnapNote } from '../../types';
import SubMenu from '../../Timeline/SubMenu';
import useSnapNote from '../../hooks/useSnapNote';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Text, Flex } from '../../../storyBook';
import { formatDate } from '../../Utils/helper';
import Loader from '../../_common/Loader';
import { GoStarFill } from 'react-icons/go';
import useTimeline from '../../hooks/useTimeline';
import { useLocation } from 'react-router-dom';
import ControlPanel from './ControlPanel';
import { Crop } from 'react-image-crop';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';

import {
  CardStyle,
  Label,
  ImageGallery,
  Image,
  LeftArrow,
  RightArrow,
  PreviewImage,
  RotableContainer,
  DownloadIcon,
  ImageContainer,
  ImageOverlay,
} from './style';
import theme from '../../../theme';
import ImageCrop from './ImageCrop';

const SnapNoteCard = ({
  snapNote,
  toggleStar,
}: {
  snapNote: SnapNote;
  toggleStar: () => void;
}) => {
  const { deleteSnapNote, updateSnapNoteImage, loading } = useSnapNote();
  const galleryRef = useRef<HTMLDivElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [originalImage, setOriginalImage] = useState<string | null>(null); // Store original image
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const selfieSegmentationRef = useRef<SelfieSegmentation | null>(null);

  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [zoom, setZoom] = useState(1); // Zoom state for scaling
  const [isPinching, setIsPinching] = useState(false);
  const [showPreviewImage, setshowPreviewImage] = useState(true);
  const [isPanning, setIsPanning] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const [isCropMode, setIsCropMode] = useState(false);
  const [hasBackgroundChanged, setHasBackgroundChanged] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [hideEditIcon, setHideEditIcon] = useState(true);
  const [editingImagePath, setEditingImagePath] = useState<string | null>(null);
  const [isHideAutoMask, setIsHideAutoMask] = useState(true);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const backgroundColorRef = useRef(backgroundColor);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 35,
    height: 50,
    x: 35,
    y: 30,
  });

  const { addSnapnoteToTimeline } = useTimeline();

  useEffect(() => {
    const loadModel = async () => {
      try {
        const selfieSegmentation = new SelfieSegmentation({
          locateFile: (file) =>
            `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
        });

        selfieSegmentation.setOptions({
          modelSelection: 1,
        });

        selfieSegmentation.onResults(onResults);
        selfieSegmentationRef.current = selfieSegmentation;

        selfieSegmentation.onResults(onResults);
        selfieSegmentationRef.current = selfieSegmentation;
      } catch (error) {
        console.error('Failed to load the model:', error);
      }
    };

    loadModel();

    // Cleanup to prevent memory leaks
    return () => {
      if (selfieSegmentationRef.current) {
        selfieSegmentationRef.current.close(); // Close and clean up the model
      }
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // Updated onResults function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onResults = (results: any) => {
    const inputImage = results.image;
    if (!inputImage) return;

    const width = inputImage.width;
    const height = inputImage.height;

    // Create the main canvas
    const canvasElement = document.createElement('canvas');
    canvasElement.width = width;
    canvasElement.height = height;
    const canvasCtx = canvasElement.getContext('2d');
    if (!canvasCtx) return;

    // Clear the canvas
    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.filter = 'blur(4px) contrast(1.8)';

    // Draw the segmentation mask
    canvasCtx.drawImage(results.segmentationMask, 0, 0, width, height);
    canvasCtx.filter = 'none';

    // Get pixel data from the mask
    const maskData = canvasCtx.getImageData(0, 0, width, height);

    // Process the mask for alpha thresholding and anti-aliasing
    const alphaThreshold = 170;
    for (let i = 0; i < maskData.data.length; i += 4) {
      const alpha = maskData.data[i + 3];
      if (alpha > alphaThreshold) {
        // Gradual transition for anti-aliasing
        maskData.data[i + 3] = Math.min(255, alpha); // Smoothen edge transparency
      } else {
        maskData.data[i + 3] = 0; // Fully transparent
      }
    }

    canvasCtx.putImageData(maskData, 0, 0);

    // Apply compositing for the subject
    canvasCtx.globalCompositeOperation = 'source-in';
    canvasCtx.drawImage(inputImage, 0, 0, width, height);

    // Feather edges with additional blur for a polished look
    canvasCtx.globalCompositeOperation = 'source-over';

    // Add background color
    canvasCtx.globalCompositeOperation = 'destination-over';
    canvasCtx.fillStyle = backgroundColorRef.current;
    canvasCtx.fillRect(0, 0, width, height);

    // Reset composite operation
    canvasCtx.globalCompositeOperation = 'source-over';

    // Generate the final image data URL
    const dataUrl = canvasElement.toDataURL();
    setPreviewImage(dataUrl);
  };

  useEffect(() => {
    backgroundColorRef.current = backgroundColor;
  }, [backgroundColor]);

  // Apply background change whenever background color is updated
  const applyBackgroundChange = async () => {
    if (selfieSegmentationRef.current && originalImage) {
      setHasBackgroundChanged(true);
      const img = new window.Image();
      img.crossOrigin = 'anonymous';
      img.src = originalImage;
      img.onload = async () => {
        if (selfieSegmentationRef.current) {
          await selfieSegmentationRef.current.send({ image: img });
        }
      };
    }
  };
  const handleEditClick = () => {
    setIsEditPanelOpen(true);
    setHideEditIcon(false);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const timelineId = query.get('timelineId');

  const history = useHistory();
  const path = useRouteMatch();

  const { label, content, dateCreated } = snapNote;

  if (loading) {
    return <Loader isLoading={true} />;
  }

  const updateArrowVisibility = () => {
    const gallery = galleryRef.current;
    if (gallery) {
      setShowLeftArrow(gallery.scrollLeft > 0);
      setShowRightArrow(
        gallery.scrollLeft < gallery.scrollWidth - gallery.clientWidth
      );
    }
  };

  useEffect(() => {
    const gallery = galleryRef.current;
    if (gallery) {
      updateArrowVisibility();
      const handleScroll = () => updateArrowVisibility();
      gallery.addEventListener('scroll', handleScroll);
      return () => gallery.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const gallery = galleryRef.current;
    if (gallery) {
      if (direction === 'left') {
        gallery.scrollBy({ left: -gallery.offsetWidth, behavior: 'smooth' });
      } else {
        gallery.scrollBy({ left: gallery.offsetWidth, behavior: 'smooth' });
      }
    }
  };

  const handleImageClick = (index: number) => {
    const imageUrl = (snapNote.imageUrls ?? [])[index];
    const imagePath = (snapNote.images ?? [])[index];
    setPreviewImage(imageUrl);
    setOriginalImage(imageUrl);
    setEditingImagePath(imagePath);
  };

  const downloadImage = (imageUrl: string) => {
    const downloadUrl = croppedImage || imageUrl;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', label || 'downloadedImage.jpg');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closePreview = () => {
    setPreviewImage(null);
    setIsCropMode(false);
  };

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  const handleDelete = async () => {
    if (snapNote._id) {
      await deleteSnapNote(snapNote._id);
    }
  };

  const handleEdit = () => {
    history.push(`${path.url}/edit/${snapNote._id}`);
  };

  // Handle crop change
  const onCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
    showPreviewImage && setshowPreviewImage(false);
  };

  // Generate the cropped image once cropping is complete
  const onCropComplete = useCallback((crop: Crop) => {
    setCompletedCrop(crop);
  }, []);

  // Convert pixel values to millimeters based on DPI
  const convertPixelsToMm = (pixels: number, dpi: number = 96) =>
    (pixels / dpi) * 25.4;

  const cropSize =
    crop.width && crop.height && isCropMode
      ? `${convertPixelsToMm(crop.width).toFixed(1)} x ${convertPixelsToMm(
          crop.height
        ).toFixed(1)} mm`
      : '';
  const saveCroppedImage = () => {
    if (!completedCrop || !previewImage || !snapNote._id || !editingImagePath)
      return;

    const image = new window.Image();
    image.crossOrigin = 'anonymous';
    image.src = previewImage;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Get natural dimensions
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;

      // Calculate scaling factors
      const scaleX = naturalWidth / image.width;
      const scaleY = naturalHeight / image.height;

      // Adjust the crop coordinates
      const pixelCrop = {
        x: completedCrop.x * scaleX,
        y: completedCrop.y * scaleY,
        width: completedCrop.width * scaleX,
        height: completedCrop.height * scaleY,
      };

      // Set canvas dimensions
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      // Convert the canvas content to a blob
      canvas.toBlob(async (blob) => {
        if (blob) {
          const file = new File([blob], 'edited-image.png', {
            type: 'image/png',
          });

          const formData = new FormData();
          formData.append('image', file);
          formData.append('imagePath', editingImagePath);

          // Upload the edited image to the server
          if (snapNote._id) {
            await updateSnapNoteImage(snapNote._id, formData);
          } else {
            console.error('SnapNote ID is undefined');
          }

          // Update the preview image with the edited image
          const croppedDataUrl = canvas.toDataURL('image/png');
          setPreviewImage(croppedDataUrl);

          // Update the imageRef to point to the new cropped image
          if (imageRef.current) {
            imageRef.current.src = croppedDataUrl;
          }

          // Exit crop mode
          setIsCropMode(false);
        } else {
          console.error('Failed to get blob from canvas');
        }
      }, 'image/png');
    };

    image.onerror = () => {
      console.error('Image could not be loaded for cropping.');
    };
  };

  const resetToOriginal = () => {
    setPreviewImage(originalImage);
    setCroppedImage(null);
    setBackgroundColor('#ffffff');
    setIsCropMode(false);
    setHasBackgroundChanged(false);
    setshowPreviewImage(true);
    setIsPanning(false);
    setIsHideAutoMask(true);
    setZoom(1);
    setTranslateX(0);
    setTranslateY(0);
  };

  return (
    <CardStyle my={[1, 2, 3]}>
      <RotableContainer>
        <RotatableIcon
          _id={snapNote._id || ''}
          onToggle={handleMenuToggle}
          isOpen={openMenuId === snapNote._id}
        />
        {openMenuId === snapNote._id && (
          <SubMenu
            id={snapNote._id}
            timelineId={timelineId || ''}
            showEditOption={true}
            snapNote
            onEdit={handleEdit}
            onDelete={handleDelete}
            setOpenMenuId={setOpenMenuId}
            addToTimeline
            addSnapnoteToTimeline={addSnapnoteToTimeline}
          />
        )}
      </RotableContainer>

      <Label>
        <Flex flexDirection="column">
          <Text as="small">{formatDate(dateCreated)}</Text>
          <Flex alignItems="center" justifyContent="space-between" mr={1}>
            {label}

            <GoStarFill
              size={20}
              color={
                snapNote.isStarred ? theme.colors.pending : theme.colors.medium
              }
              style={{ cursor: 'pointer' }}
              onClick={toggleStar}
            />
          </Flex>
        </Flex>
      </Label>
      {content && (
        <Box mb={3}>
          <Card background={theme.colors.ultraLight} padding={theme.spacing.sm}>
            {content}
          </Card>
        </Box>
      )}

      {showLeftArrow && (
        <LeftArrow onClick={() => scroll('left')}>
          <FaArrowLeft />
        </LeftArrow>
      )}
      <ImageGallery ref={galleryRef}>
        {snapNote.imageUrls?.map((imageUrl, index) => (
          <ImageContainer key={index}>
            <Image
              src={imageUrl}
              crossOrigin="anonymous"
              alt={`SnapNote Image ${index + 1}`}
              onClick={() => handleImageClick(index)}
            />
            <ImageOverlay>
              <DownloadIcon onClick={() => downloadImage(imageUrl)} />
            </ImageOverlay>
          </ImageContainer>
        ))}
      </ImageGallery>

      {showRightArrow && (
        <RightArrow onClick={() => scroll('right')}>
          <FaArrowRight />
        </RightArrow>
      )}

      <Modal
        onClose={closePreview}
        isOpen={!!previewImage}
        //***{temporary turn off edit image}***
        // isEditIcon
        hideEditIcon={hideEditIcon}
        handleEditClick={handleEditClick}
        panelBackground
      >
        {previewImage && !isCropMode && !isEditPanelOpen && (
          <PreviewImage
            src={previewImage}
            alt="Preview"
            crossOrigin="anonymous"
          />
        )}

        {previewImage && isEditPanelOpen && (
          <ImageCrop
            src={previewImage}
            crop={crop}
            onCropChange={onCropChange}
            zoom={zoom}
            setZoom={setZoom}
            isPinching={isPinching}
            setIsPinching={setIsPinching}
            onCropComplete={onCropComplete}
            imageRef={imageRef}
            isCropMode={isCropMode}
            showPreviewImage={showPreviewImage}
            isPanning={isPanning}
            setIsPanning={setIsPanning}
            translateX={translateX}
            translateY={translateY}
            setTranslateX={setTranslateX}
            setTranslateY={setTranslateY}
          />
        )}

        {isEditPanelOpen && (
          <ControlPanel
            setIsEditPanelOpen={setIsEditPanelOpen}
            setHideEditIcon={setHideEditIcon}
            saveCroppedImage={saveCroppedImage}
            resetToOriginal={resetToOriginal}
            setIsCropMode={setIsCropMode}
            isCropMode={isCropMode}
            cropSize={cropSize}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            applyBackgroundChange={applyBackgroundChange}
            setHasBackgroundChanged={setHasBackgroundChanged}
            hasBackgroundChanged={hasBackgroundChanged}
            setIsHideAutoMask={setIsHideAutoMask}
            isHideAutoMask={isHideAutoMask}
          />
        )}
      </Modal>
    </CardStyle>
  );
};

export default SnapNoteCard;
