import { useState } from 'react';
import {
  Modal,
  Box,
  Text,
  TextInput,
  Card,
  Button,
  Flex,
} from '../../../../storyBook';
import { Hr } from '../../../_common/style';
import usePassword from '../../../hooks/usePassword';
import { siteValidation } from '../../../Utils/validation';
import { ErrorMessage } from '../../../_common/style';
import { encrypt } from '../../../Utils/helper';
import { ImSpinner8 } from 'react-icons/im';

type AddPasswordModalProps = {
  setAddModal: (addModal: boolean) => void;
  isEditMode?: boolean;
  initialData?: { site: string; userName: string; password: string };
  onEdit?: (data: {
    website: string;
    username: string;
    password: string;
  }) => void;
};

const AddPasswordModal: React.FC<AddPasswordModalProps> = ({
  setAddModal,
  isEditMode = false,
  initialData,
  onEdit,
}) => {
  const [site, setSite] = useState(initialData?.site || '');
  const [userName, setUserName] = useState(initialData?.userName || '');
  const [password, setPassword] = useState(initialData?.password || '');
  const { addPassword, loading } = usePassword();
  const [siteError, setSiteError] = useState(false);

  const handleClose = () => {
    setAddModal(false);
  };
  const disabled = !site || !userName || !password;

  const handleSubmit = async () => {
    const secretKey = process.env.REACT_APP_SECRET_KEY || '';

    const encryptedPassword = encrypt(password, secretKey);
    if (isEditMode && onEdit) {
      onEdit({
        website: site,
        username: userName,
        password: encryptedPassword,
      });
    } else {
      try {
        // Use the addPassword method from the context
        await addPassword({
          website: site,
          username: userName,
          password: encryptedPassword,
        });
        console.log('Password added successfully' + site + userName + password);
        setAddModal(false); // Close modal on success
      } catch (error) {
        // Handle any errors here
        console.error('Error adding password:', error);
        alert('Failed to add password');
      }
    }
  };

  return (
    <Box>
      <Modal isOpen={true} onClose={handleClose}>
        <Box m={3}>
          <Card>
            <Box>
              <Text as="h3" mb={2} pt={3} color="mediumDark">
                {isEditMode ? 'Edit Password' : 'Add New Password'}
              </Text>
              <Hr mb={3} />
              <Box mb={4}>
                <Text as="span" mb={2}>
                  Make sure that you&apos;re saving your current password for
                  this site
                </Text>
              </Box>
            </Box>
            <Box mb={4}>
              <TextInput
                autoFocus
                placeholder=" Site (example.com)"
                value={site}
                onChange={(e) => setSite(e.target.value)}
                validation={siteValidation}
                onValidation={(isValid) => setSiteError(isValid)}
                disabled={isEditMode}
              />
              {siteError && <ErrorMessage>{siteError}</ErrorMessage>}
              <TextInput
                placeholder="Username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextInput
                type="password"
                placeholder=" Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Hr mb={4} />
            <Flex flexDirection="row" textAlign="end" pb={3}>
              <Box mr={3}>
                <Button
                  styleType="primary"
                  size="small"
                  type="submit"
                  bold
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {loading ? (
                    <ImSpinner8 size={20} style={{ verticalAlign: 'middle' }} />
                  ) : isEditMode ? (
                    'Update'
                  ) : (
                    'Save'
                  )}
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondary"
                  size="small"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddPasswordModal;
